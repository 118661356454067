
import React, { useState } from "react";
import { SimpleGrid, Text, useColorModeValue, Button } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import Information from "views/admin/artist/components/Information";
import { useHistory } from 'react-router-dom';

const calcPrice = (value, defaultReturn = "-") => {
  if (!value) {
    return defaultReturn;
  }
  const deal = parseInt(value) / 1000000;
  if (deal < 1) {
    return parseFloat(deal.toFixed(2)).toString() + " Xtz";
  } else if (deal >= 1 && deal < 10) {
    return parseFloat(deal.toFixed(1)).toString() + " Xtz";
  } else {
    return deal.toFixed(0) + " Xtz";
  }
};

export default function GeneralInformation(props) {
  const { data, ...rest } = props; // assuming you pass description via props
  const [isExpanded, setIsExpanded] = useState(false);


  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const bgColorPrimary = useColorModeValue("#7551ff", "#7551ff");
  const history = useHistory();
  return (
    <Card mb={{ base: "20px", "2xl": "20px" }} {...rest} >
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='xl'
        mt='10px'
        mb='20px'>
        Stats as Collector
      </Text>
      <SimpleGrid columns='2' gap='20px'>
      <Information
          boxShadow={cardShadow}
          title='Total Spent'
          value={data.totalSpent ? calcPrice(data.totalSpent) : "-"}
        />
        <Information
          boxShadow={cardShadow}
          title='Total Art Collect'
          value={data.totalArtCollect ? data.totalArtCollect : "-"}
        />
      </SimpleGrid>

      <Button onClick={() => history.push(`/collector/${data.collectorWallet}`)}
      mt='10px'
      bgColor={bgColorPrimary}>
        Show {data.collectorName || `${data.collectorWallet.slice(0, 5)}...${data.collectorWallet.slice(-5)}`} stats as Collector
      </Button>
    </Card>
  );
}
