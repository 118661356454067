

import { SimpleGrid, Text, useColorModeValue, Button } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import Information from "views/admin/collection/components/Information";

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const monthNames = [
      "January", "February", "March", "April", "May", "June", 
      "July", "August", "September", "October", "November", "December"
  ];

  return `${day} ${monthNames[monthIndex]} ${year}`;
}

const calculateRelativeTime = (mintTime) => {
  const mintDate = new Date(mintTime);
  const now = new Date();
  const diffTime = Math.abs(now - mintDate);
  const diffMinutes = Math.floor(diffTime / (1000 * 60));
  const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffWeeks = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 7));
  const diffMonths = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 30)); // Approximate
  const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365)); // Approximate

  if (diffMinutes < 60) {
      return `${diffMinutes}m ago`;
  } else if (diffHours < 24) {
      const remainingMinutes = diffMinutes % 60 || 1;
      return `${diffHours}h and ${remainingMinutes}m ago`;
  } else if (diffDays < 7) {
      const remainingHours = diffHours % 24 || 1;
      return `${diffDays}d and ${remainingHours}h ago`;
  } else if (diffWeeks < 4) { // Approximate
      const remainingDays = diffDays % 7 || 1;
      return `${diffWeeks}w and ${remainingDays}d ago`;
  } else if (diffMonths < 12) {
      const remainingDays = diffDays % 30 || 1; // Approximate
      if(diffMonths === 0 && remainingDays >= 30){
        return `1M ago`; // Round up to 1 month if difference is 30 days or more
      } else if (diffMonths === 0 && remainingDays < 30) {
        return `${remainingDays}d ago`;
      }
      return `${diffMonths}M and ${remainingDays}d ago`;
  } else {
      const remainingMonths = diffMonths % 12 || 1;
      return `${diffYears}Y and ${remainingMonths}M ago`;
  }
}

export default function GeneralInformation(props) {
  const { category, collectionType, totalArts, totalEditions, totalBurned, firstMintTime, lastUpdate, ...rest } = props; // assuming you pass description via props

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  return (
    <Card mb={{ base: "20px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='xl'
        mt='20px'
        mb='20px'>
        Details
      </Text>
      
      <SimpleGrid columns='2' gap='20px'>
      <Information
          boxShadow={cardShadow}
          title='Category'
          value={category ? category : "-"}
        />
        <Information
          boxShadow={cardShadow}
          title='Type'
          value={collectionType ? collectionType : "-"}
        />
        <Information
          boxShadow={cardShadow}
          title='Total Art'
          value={totalArts ? totalArts : "-"}
        />
        <Information
          boxShadow={cardShadow}
          title='Total Edition'
          value={totalEditions ? totalEditions : "-"}
        />
        <Information
          boxShadow={cardShadow}
          title='Total Burned'
          value={totalBurned ? totalBurned : "-"}
        />
        <Information
          boxShadow={cardShadow}
          title='Mint time'
          value={firstMintTime ? formatDate(firstMintTime) + " (" + calculateRelativeTime(firstMintTime) + ")": "-"}
        />
        <Information
          boxShadow={cardShadow}
          title='Last update'
          value={lastUpdate ? formatDate(lastUpdate) + " (" + calculateRelativeTime(lastUpdate) + ")": "-"}
        />
      </SimpleGrid>
    </Card>
  );
}
