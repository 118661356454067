// Chakra imports
import { Icon, SimpleGrid, Text, useColorModeValue, Flex, Box, Center } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Information from "views/admin/collector/components/Information";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";


const calcPrice = (value, defaultReturn = "-") => {
  if (!value) {
    return defaultReturn;
  }
  const deal = parseInt(value) / 1000000;
  if (deal < 1) {
    return Number(deal.toFixed(2)).toLocaleString() + " Xtz";
  } else if (deal >= 1 && deal < 10) {
    return Number(deal.toFixed(1)).toLocaleString() + " Xtz";
  } else {
    return Number(deal.toFixed(0)).toLocaleString() + " Xtz";
  }
};

// Assets
export default function SellStats(props) {
  const { totalSpent, totalProfit, totalArtCollect, avgSpendEachArt, avgProfitPercentage, avgProfitAmount, buyCount, sellCount, totalSell, ...rest } = props;

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card mb={{ base: "20px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='xl'
        mt='10px'
        mb='8px'
        ml= '20px'
        align= 'left'>
        Sell Stats
      </Text>
      
      <SimpleGrid columns={{ base: '1', md: '2', xl: '2' }} gap='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Spend'
          value={calcPrice(totalSpent)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Sell'
          value={calcPrice(totalSell)}
        />

<MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Art Collected'
          value={totalArtCollect ? totalArtCollect : "-"}
        />
        
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Arts Sold'
          value={sellCount ? sellCount : "-"}
        />




        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Profit'
          value={calcPrice(totalProfit)}
        />
        
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Avg. Profit'
          value={calcPrice(avgProfitAmount)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Avg. Profit Percentage'
          value={avgProfitPercentage ? avgProfitPercentage.toFixed(0) + "%" : "-"}
        />
        
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Avg Spend Each'
          value={calcPrice(avgSpendEachArt)}
        />
     
      </SimpleGrid>
    </Card>
  );
}
