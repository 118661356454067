import React from "react";

// Chakra imports
import { Flex, useColorModeValue, Text } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  return (
    <Flex align='center' direction='column'>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'
        align= 'center'>
        P E D R O D I G
      </Text>
      <Text
      fontSize='12px'>
        beta
      </Text>
    </Flex>
  );
}

export default SidebarBrand;
