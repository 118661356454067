// Chakra imports
import React, { useState } from "react";
import {
  Box,
  Flex,
  Icon,
  Progress,
  Text,
  useColorModeValue,
  Button,
  Avatar
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import Menu from "components/menu/MainMenu";
import { FaTwitter, FaGlobe } from 'react-icons/fa';

import MiniStatistics from "components/card/MiniStatistics";

// Assets
import { MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdOutlineCloudDone } from "react-icons/md";
  import { FaExternalLinkAlt } from 'react-icons/fa';
import { Image, Link } from '@chakra-ui/react';

export default function Banner(props) {

  const { imageUrl,name, description, twitter, website, wallet, ...rest } = props; // assuming you pass description via props
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = () => setIsExpanded(!isExpanded);
  let textToShow ="-"
  if(description){
    textToShow = isExpanded ? description : `${description.slice(0, 250)}`;
  }
  const objktLink = `https://objkt.com/profile/${wallet}/created?utm_source=pedrodig&utm_medium=referral&utm_campaign=objkt`;
  const bgColorPrimary = useColorModeValue("#7551ff", "#7551ff");
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Card mb={{ base: "20px", lg: "20px" }} align='center' h='100%'>

<IconBox
  mx='auto'
  h='100px'
  w='100px'
  mt='20px'
  icon={
    <Avatar src={imageUrl ? imageUrl : "-"} borderRadius="full" h='100px' w='100px' alt="description" />
  }
  bg={box}
/>
      <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px'>
        {name ? (name || `${wallet.slice(0, 5)}...${wallet.slice(-5)}`) : "-"}
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        {description}
      </Text>
   
      <Flex justifyContent='center' mt='10px'>
  {twitter && <Link href={twitter} isExternal mx='5px'>
    <Icon as={FaTwitter} w={6} h={6} />
  </Link>}
  {website && <Link href={website} isExternal mx='5px'>
    <Icon as={FaGlobe} w={6} h={6} />
  </Link>}
</Flex>
      
<Flex
          mt='10px'
          mb='20px'
          justifyContent='space-between'
          direction={{ base: "column", md: "column" }}
          align={{ base: "start", md: "center" }
          }>
{wallet && <Button 
        href={objktLink} 
            as="a" 
        
            target="_blank" 
            rel="noopener noreferrer" 
            mt={{ base: "10px", md: "0" }} 

            bg={bgColorPrimary}
            rightIcon={<FaExternalLinkAlt />}
            textColor='white'
          >
            Show on OBJKT
          </Button>}
          </Flex>
    </Card>
  );
}
