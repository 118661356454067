import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from "react-icons/md";

// Admin Imports

import Art from "views/admin/art";
import Collection from "views/admin/collection";
import Artist from "views/admin/artist";
import Collector from "views/admin/collector";
import ArtTable from "views/admin/artTable";
import ArtistTable from "views/admin/artistTable";
import CollectionTable from "views/admin/collectionTable";
import CollectorTable from "views/admin/collectorTable";
import Main from "views/admin/main";

// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    name: "Main",
    layout: "/",
    path: "/",
    // icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Main,
  },
  {
    name: "Arts",
    layout: "/",
    path: "arts",
    // icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: ArtTable,
  },
  {
    name: "Collections",
    layout: "/",
    path: "collections",
    // icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: CollectionTable,
  },
  {
    name: "Artists ",
    layout: "/",
    path: "artists",
    // icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: ArtistTable,
  },
  {
    name: "Collectors",
    layout: "/",
    path: "collectors",
    // icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: CollectorTable,
  },
  {
  name: "Art",
  layout: "/",
  path: "art/:contractAddress/:tokenId",
  // icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  component: Art,
},
{
  name: "Collection",
  layout: "/",
  path: "collection/:contractAddress",
  // icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  component: Collection,
},
{
  name: "Artist",
  layout: "/",
  path: "artist/:walletAddress",
  // icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  component: Artist,
},
{
  name: "Collector",
  layout: "/",
  path: "collector/:walletAddress",
  // icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  component: Collector,
},

];

export default routes;



// {
//   name: "Sign In",
//   layout: "/auth",
//   path: "/sign-in",
//   icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
//   component: SignInCentered,
// },
// {
//   name: "RTL Admin",
//   layout: "/rtl",
//   path: "/rtl-default",
//   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
//   component: RTL,
// },
// {
//   name: "Main Dashboard",
//   layout: "/admin",
//   path: "/default",
//   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
//   component: MainDashboard,
// },
// {
//   name: "NFT Marketplace",
//   layout: "/admin",
//   path: "/nft-marketplace",
//   icon: (
//     <Icon
//       as={MdOutlineShoppingCart}
//       width='20px'
//       height='20px'
//       color='inherit'
//     />
//   ),
//   component: NFTMarketplace,
//   secondary: true,
// },
// {
//   name: "Data Tables",
//   layout: "/admin",
//   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
//   path: "/data-tables",
//   component: DataTables,
// },


// {
//   name: "Art",
//   layout: "/admin",
//   path: "/art/:address/:id",
//   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
//   component: Art,
// },
// {
//   name: "Collection",
//   layout: "/admin",
//   path: "/collection/:id",
//   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
//   component: Collection,
// },
// {
//   name: "Artist",
//   layout: "/admin",
//   path: "/artist/:id",
//   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
//   component: Artist,
// },
// {
//   name: "Collector",
//   layout: "/admin",
//   path: "/collector/:id",
//   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
//   component: Collector,
// },