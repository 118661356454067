// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { pieChartData, pieChartOptions } from "variables/charts";
import { VSeparator } from "components/separator/Separator";
import React from "react";

export default function Conversion(props) {
  const { data, ...rest } = props;
  let sum = 0;
  let percentages = [];

  if (Array.isArray(data)) {
    // calculate the sum of all numbers in the array
    sum = data.reduce((a, b) => a + b, 0);
  
    // calculate the percentage of each number
    percentages = data.map(function(num) {
      return (num / sum) * 100;
    });
  }
  console.log("selllwayyys", data)
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card p='20px' align='center' direction='column'justifyContent='space-between' w='100%' {...rest} h='100%' >
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        
        alignItems='center'
        w='100%'
        mb='8px'
          
        >
        <Text me='auto'
          color={textColor}
          fontSize='xl'
          fontWeight='700'
          lineHeight='100%'
          mt='10px'>
          Sell Types
        </Text>
      
      </Flex>

      { data && data.length > 0 ? 
  <PieChart
    h='100%'
    w='100%'
    chartData={data}
    chartOptions={pieChartOptions}
  />
  :
  <Text>Loading...</Text> // Or any other placeholder component
}
      <Card
        bg={cardColor}
        flexDirection={{ base: 'column', md: 'row', xl: 'row' }}
        boxShadow={cardShadow}
        w='100%'
        p='15px'
        px='20px'
        mt='15px'
        mx='auto'
        justifyContent='space-evenly'>
        <Flex direction='column' py='5px' >
          <Flex align='center'>
            <Box h='8px' w='8px' bg='brand.500' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.600'
              fontWeight='700'
              mb='5px'>
              List
            </Text>
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
          {percentages[0] ? percentages[0].toFixed(0) + '%' : '-'}
          </Text>
        </Flex>
        <VSeparator mx={{ base: "20px", xl: "20px", "2xl": "30px" }} />
        <Flex direction='column' py='5px' me='10px'>
          <Flex align='center'>
            <Box h='8px' w='8px' bg='#6AD2FF' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.600'
              fontWeight='700'
              mb='5px'>
              Auction
            </Text>
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
          {percentages[1] ? percentages[1].toFixed(0) + '%' : '-'}
          </Text>
        </Flex>
        <VSeparator mx={{ base: "20px", xl: "20px", "2xl": "30px" }} />
        <Flex direction='column' py='5px' me='10px'>
          <Flex align='center'>
            <Box h='8px' w='8px' bg='#6AD2FF' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.600'
              fontWeight='700'
              mb='5px'>
              Offer
            </Text>
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
          {percentages[2] ? percentages[2].toFixed(0) + '%' : '-'}
          </Text>
        </Flex>
        <VSeparator mx={{ base: "20px", xl: "20px", "2xl": "30px" }} />
        <Flex direction='column' py='5px' me='10px'>
          <Flex align='center'>
            <Box h='8px' w='8px' bg='#6AD2FF' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.600'
              fontWeight='700'
              mb='5px'>
              OE
            </Text>
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
          {percentages[3] ? percentages[3].toFixed(0) + '%' : '-'}
          </Text>
        </Flex>
      </Card>
    </Card>
  );
}
