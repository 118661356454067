// Chakra Imports
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Link, Text, useColorModeValue, IconButton, useBreakpointValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import NavMenuLinks from 'components/navbar/NavMenuLinks';
import { HamburgerIcon } from '@chakra-ui/icons';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import { ThemeEditor } from './ThemeEditor';

export default function AdminNavbar(props) {
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', changeNavbar);

		return () => {
			window.removeEventListener('scroll', changeNavbar);
		};
	});
	const navbarIcon = useColorModeValue('gray.400', 'white');
	const { secondary, message, brandText, routes } = props;
	const display = useBreakpointValue({ base: 'none', md: 'none', xl: 'flex' });
	const burgerDisplay = useBreakpointValue({ base: 'block', sm: 'block', md: 'block', xl: 'none' });
	// Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
	let mainText = useColorModeValue('navy.700', 'white');
	let secondaryText = useColorModeValue('gray.700', 'white');
	const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
	let navbarPosition = 'fixed';
	let navbarFilter = 'none';
	let navbarBackdrop = 'blur(20px)';
	let navbarShadow = 'none';
	let navbarBg = useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)');
	let navbarBorder = 'transparent';
	let secondaryMargin = '0px';
	let paddingX = '15px';
	let gap = '0px';
	const changeNavbar = () => {
		if (window.scrollY > 1) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	return (
	
		<Box
			position={navbarPosition}
			boxShadow={navbarShadow}
			bg={navbarBg}
			borderColor={navbarBorder}
			filter={navbarFilter}
			backdropFilter={navbarBackdrop}
			backgroundPosition='center'
			backgroundSize='cover'
			borderRadius='16px'
			borderWidth='1.5px'
			borderStyle='solid'
			transitionDelay='0s, 0s, 0s, 0s'
			transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
			transition-property='box-shadow, background-color, filter, border'
			transitionTimingFunction='linear, linear, linear, linear'
			alignItems={{ xl: 'center' }}
			display={secondary ? 'block' : 'flex'}
			minH='75px'
			justifyContent={{ xl: 'space-between' }}
			lineHeight='25.6px'
			mx='auto'
			mt={secondaryMargin}
			pb='8px'
			right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
			px={{
				sm: paddingX,
				md: '10px'
			}}
			ps={{
				xl: '12px'
			}}
			pt='8px'
			top={{ base: '12px', md: '16px', lg: '20px', xl: '20px' }}
			w={{
				base: 'calc(100vw - 6%)',
				md: 'calc(100vw - 8%)',
				lg: 'calc(100vw - 6%)',
				xl: 'calc(100vw - 4%)',
				'2xl': 'calc(100vw - 4%)'
			}}>
			<Flex
        w='100%'
        alignItems='center'
        justifyContent='space-between'>
        <Flex direction='row'>
		<Link href="https://pedrodig.xyz" _hover={{ textDecoration: 'none', color: 'white' }} >
          <Text color={textColorPrimary} fontWeight='bold' fontSize={{ base: 'xl', sm: 'xl', md: '2xl'}}>
            P E D R O D I G
          </Text>
		  </Link>
		  <Text
      fontSize='12px'
	  mt={{ base: '3px', sm: '3px', md: '5px'}}
	  ml='2px'
	  textColor='#c8bcf9'>
        beta
      </Text>

	  <Box display={burgerDisplay} ml='20px'>
		<ThemeEditor navbarIcon={navbarIcon}  />
		</Box>
        </Flex>

        <Box display={display}>
		
          <NavMenuLinks routes={routes} />
        </Box>
		
        <Box display={display}>
		
		<AdminNavbarLinks
						onOpen={props.onOpen}
						logoText={props.logoText}
						secondary={props.secondary}
						fixed={props.fixed}
						scrolled={scrolled}
					/>
        </Box>
		
		
		<SidebarResponsive secondary={props.secondary} routes={routes} />
        {/* <IconButton 
          aria-label="Navigation Menu" 
          icon={<HamburgerIcon />} 
          size="lg" 
          variant="outline"
           
          onClick={props.onOpen} // you can modify onOpen to handle the display of mobile menu
        /> */}
      </Flex>
			{secondary ? <Text color='white'>{message}</Text> : null}
		</Box>

	);
}

AdminNavbar.propTypes = {
	brandText: PropTypes.string,
	variant: PropTypes.string,
	secondary: PropTypes.bool,
	fixed: PropTypes.bool,
	onOpen: PropTypes.func
};



