// Chakra imports
import { SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Information from "views/admin/art/components/Information";
import Informationl2s from "views/admin/art/components/Informationl2s";

const calculateRelativeTime = (mintTime) => {
  const diffSeconds = Math.abs(mintTime);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffSeconds / (60 * 60));
  const diffDays = Math.floor(diffSeconds / (60 * 60 * 24));
  const diffWeeks = Math.floor(diffSeconds / (60 * 60 * 24 * 7));
  const diffMonths = Math.floor(diffSeconds / (60 * 60 * 24 * 30)); // Approximation

  if (diffSeconds === 0) {
    return "-";
  } else if (diffSeconds < 60) {
    return `${diffSeconds}s`;
  } else if (diffMinutes < 60) {
    return `${diffMinutes}m`;
  } else if (diffHours < 24) {
    const remainingMinutes = diffMinutes % 60;
    return remainingMinutes === 0 ? `${diffHours}h` : `${diffHours}h and ${remainingMinutes}m`;
  } else if (diffDays < 7) {
    const remainingHours = diffHours % 24;
    return remainingHours === 0 ? `${diffDays}d` : `${diffDays}d and ${remainingHours}h`;
  } else if (diffWeeks < 4) {
    const remainingDays = diffDays % 7;
    return remainingDays === 0 ? `${diffWeeks}w` : `${diffWeeks}w and ${remainingDays}d`;
  } else {
    const remainingWeeks = diffWeeks % 4;
    // Ensuring that if diffMonths is 0, we return the weeks instead
    return (diffMonths === 0 && remainingWeeks === 0) 
      ? "0" 
      : (diffMonths === 0 
        ? `${remainingWeeks}w` 
        : (remainingWeeks === 0 
          ? `${diffMonths}M` 
          : `${diffMonths}M and ${remainingWeeks}w`));
  }
};




// Assets
export default function GeneralInformation(props) {
  const { primaryListToSellTime, secondaryListToSellTime, ...rest } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card mb={{ base: "20px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='xl'
        mt='10px'
        mb='8px'
        ml='10px'
        align= 'left'>
        List To Sell Time
      </Text>
      <SimpleGrid columns='2' gap='20px' mt='20px'>
        <Informationl2s
          boxShadow={cardShadow}
          title='Avg Primary'
          value={primaryListToSellTime ? calculateRelativeTime(primaryListToSellTime.Avg) : "-"}
        />
        <Informationl2s
          boxShadow={cardShadow}
          title='Avg Secondary'
          value={secondaryListToSellTime ? calculateRelativeTime(secondaryListToSellTime.Avg) : "-"}
        />
        <Informationl2s
          boxShadow={cardShadow}
          title='Min Primary'
          value={primaryListToSellTime ? calculateRelativeTime(primaryListToSellTime.min) : "-"}
        />
        <Informationl2s
          boxShadow={cardShadow}
          title='Min Secondary'
          value={secondaryListToSellTime ? calculateRelativeTime(secondaryListToSellTime.min) : "-"}
        />
        <Informationl2s
          boxShadow={cardShadow}
          title='Max Primary'
          value={primaryListToSellTime ? calculateRelativeTime(primaryListToSellTime.max) : "-"}
        />       
        <Informationl2s
          boxShadow={cardShadow}
          title='Max Secondary'
          value={secondaryListToSellTime ? calculateRelativeTime(secondaryListToSellTime.max) : "-"}
        />
      </SimpleGrid>
    </Card>
  );
}
