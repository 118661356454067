import {
  AvatarGroup,
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useState } from "react";
import { IoHeart, IoHeartOutline } from "react-icons/io5";


const calculateRelativeTime = (mintTime) => {
  const diffSeconds = Math.abs(mintTime);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffSeconds / (60 * 60));
  const diffDays = Math.floor(diffSeconds / (60 * 60 * 24));
  const diffWeeks = Math.floor(diffSeconds / (60 * 60 * 24 * 7));
  const diffMonths = Math.floor(diffSeconds / (60 * 60 * 24 * 30)); // Approximation

  if (diffSeconds === 0) {
    return `-`;
  } else if (diffSeconds < 60) {
    return `${diffSeconds}s`;
  } else if (diffMinutes < 60) {
    return `${diffMinutes}m`;
  } else if (diffHours < 24) {
    const remainingMinutes = diffMinutes % 60;
    return `${diffHours}h and ${remainingMinutes}m`;
  } else if (diffDays < 7) {
    const remainingHours = diffHours % 24;
    return `${diffDays}d and ${remainingHours}h`;
  } else if (diffWeeks < 4) {  // If less than 4 weeks, we still consider it as weeks
    const remainingDays = diffDays % 7;
    return `${diffWeeks}w and ${remainingDays}d`;
  } else {
    const remainingWeeks = diffWeeks % 4;
    return `${diffMonths}M and ${remainingWeeks}w`;
  }
}

const ipfsToHttp = (ipfsUrl) => {
  if(ipfsUrl) {
    return ipfsUrl.replace('ipfs://', 'https://ipfs.io/ipfs/');
  }
  return null;
}

export default function NFT(props) {
  const { image, name, author, avgProfit, avgList, collectors, totalPrimarySell, totalSecondarySell } = props;
  const [like, setLike] = useState(false);
  const textColor = useColorModeValue("navy.700", "white");
  const textColorBid = useColorModeValue("brand.500", "white");
  return (
    <Card p='20px' maxWidth="250px" mx="auto">
      <Flex direction="column" align="center">
        <Flex
          justifyContent="center"
          borderRadius='100%'
          w='100px'
          h='100px'
          mb="10px"
          overflow='hidden'
        >
          <Image
            src={ipfsToHttp(image)}
            alt='Banner image'
            objectFit='cover'
            w='100%'
            h='100%'
          />
        </Flex>
        <Flex flexDirection='column' justify='space-between' w="100%">
          <Text color={textColor} fontWeight='bold' mb='5px' align="center">
            {name}
          </Text>
          <Text color='secondaryGray.600' fontSize='sm' fontWeight='400' align="center">
            {author}
          </Text>
          <Text fontWeight='700' fontSize='sm' color={textColorBid} mt="10px" align="center">
          Total Primary: {totalPrimarySell}
          </Text>
          <Text fontWeight='700' fontSize='sm' color={textColorBid} mt="10px" align="center">
          Total Secondary: {totalSecondarySell}
          </Text>
          <Text fontWeight='700' fontSize='sm' color={textColorBid} mt="10px" align="center">
            Collectors: {collectors}
          </Text>
     
        </Flex>
      </Flex>
    </Card>
  );
}



// // Chakra imports
// import {
//   AvatarGroup,
//   Avatar,
//   Box,
//   Button,
//   Flex,
//   Icon,
//   Image,
//   Link,
//   Text,
//   useColorModeValue,
// } from "@chakra-ui/react";
// // Custom components
// import Card from "components/card/Card.js";
// // Assets
// import React, { useState } from "react";
// import { IoHeart, IoHeartOutline } from "react-icons/io5";

// export default function NFT(props) {
//   const { image, name, author, bidders, download, currentbid } = props;
//   const [like, setLike] = useState(false);
//   const textColor = useColorModeValue("navy.700", "white");
//   const textColorBid = useColorModeValue("brand.500", "white");
//   return (
//     <Card p='20px'>
//       <Flex direction={{ base: "column" }} justify='center'>
//         <Box mb={{ base: "20px", "2xl": "20px" }} position='relative'>
//           <Image
//             src={image}
//             w={{ base: "100%", "3xl": "100%" }}
//             h={{ base: "100%", "3xl": "100%" }}
//             borderRadius='20px'
//           />
//           <Button
//             position='absolute'
//             bg='white'
//             _hover={{ bg: "whiteAlpha.900" }}
//             _active={{ bg: "white" }}
//             _focus={{ bg: "white" }}
//             p='0px !important'
//             top='14px'
//             right='14px'
//             borderRadius='50%'
//             minW='36px'
//             h='36px'
//             onClick={() => {
//               setLike(!like);
//             }}>
//             <Icon
//               transition='0.2s linear'
//               w='20px'
//               h='20px'
//               as={like ? IoHeart : IoHeartOutline}
//               color='brand.500'
//             />
//           </Button>
//         </Box>
//         <Flex flexDirection='column' justify='space-between' h='100%'>
//           <Flex
//             justify='space-between'
//             direction={{
//               base: "row",
//               md: "column",
//               lg: "row",
//               xl: "column",
//               "2xl": "row",
//             }}
//             mb='auto'>
//             <Flex direction='column'>
//               <Text
//                 color={textColor}
//                 fontSize={{
//                   base: "xl",
//                   md: "lg",
//                   lg: "lg",
//                   xl: "lg",
//                   "2xl": "md",
//                   "3xl": "lg",
//                 }}
//                 mb='5px'
//                 fontWeight='bold'
//                 me='14px'>
//                 {name}
//               </Text>
//               <Text
//                 color='secondaryGray.600'
//                 fontSize={{
//                   base: "sm",
//                 }}
//                 fontWeight='400'
//                 me='14px'>
//                 {author}
//               </Text>
//             </Flex>
//             <AvatarGroup
//               max={3}
//               color={textColorBid}
//               size='sm'
//               mt={{
//                 base: "0px",
//                 md: "10px",
//                 lg: "0px",
//                 xl: "10px",
//                 "2xl": "0px",
//               }}
//               fontSize='12px'>
//               {bidders.map((avt, key) => (
//                 <Avatar key={key} src={avt} />
//               ))}
//             </AvatarGroup>
//           </Flex>
//           <Flex
//             align='start'
//             justify='space-between'
//             direction={{
//               base: "row",
//               md: "column",
//               lg: "row",
//               xl: "column",
//               "2xl": "row",
//             }}
//             mt='25px'>
//             <Text fontWeight='700' fontSize='sm' color={textColorBid}>
//               Current Bid: {currentbid}
//             </Text>
//             <Link
//               href={download}
//               mt={{
//                 base: "0px",
//                 md: "10px",
//                 lg: "0px",
//                 xl: "10px",
//                 "2xl": "0px",
//               }}>
//               <Button
//                 variant='darkBrand'
//                 color='white'
//                 fontSize='sm'
//                 fontWeight='500'
//                 borderRadius='70px'
//                 px='24px'
//                 py='5px'>
//                 Place Bid
//               </Button>
//             </Link>
//           </Flex>
//         </Flex>
//       </Flex>
//     </Card>
//   );
// }
