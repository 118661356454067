// Chakra imports
import {
  Box,
  Flex,
  useColorModeValue,
  Button,
  ButtonGroup,
  Spinner,
  Alert,
  AlertDescription,
  Text,
  AlertIcon,
  Link,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaDiscord } from "react-icons/fa";
// Custom components
import General from "views/admin/collection/components/General";
import Storage from "views/admin/collection/components/Storage";
import SellStats from "views/admin/collection/components/SellStats";
import ListToSell from "views/admin/collection/components/ListToSell";
import Collectors from "views/admin/collection/components/Collectors";
import Chart from "views/admin/collection/components/Chart";
import Auction from "views/admin/collection/components/Auction";
import SellWays from "views/admin/collection/components/SellWays";
import React from "react";
import ReactGA from 'react-ga';
import { useHistory } from "react-router-dom";

export default function Overview() {
  const { contractAddress } = useParams(); // Get contractAddress and tokenId from the URL
  const [range, setRange] = useState("all");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const MemoizedStorage = React.memo(Storage);
  const MemoizedGeneral = React.memo(General);

  const history = useHistory();
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  
    // Listen for changes
    return history.listen(location => {
      ReactGA.pageview(location.pathname + location.search);
    });
  }, [history]);

  const handleRangeChange = async (newRange) => {
    setLoading(true);
    setRange(newRange);
    try {
      let fetchURL = `https://api.pedrodig.xyz/collection/${newRange}/${contractAddress}`;
      const response = await fetch(fetchURL, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const mid = await response.json();
      if (mid.error === "No item found with this address") {
        setIsErrorDialogOpen(true);
        setData(mid);
        console.log(range, isErrorDialogOpen);
        setLoading(false);
        return;
      }
      setData(mid);
      console.log(mid);
      setLoading(false);
      setIsErrorDialogOpen(false);
    } catch (error) {
      console.error("Error fetching art data:", error);
    }
  };
  const bgColorPrimary = useColorModeValue("#7551ff", "#7551ff");
  const selectedStyle = { backgroundColor: bgColorPrimary };

  useEffect(() => {
    handleRangeChange(range);
  }, [contractAddress]);

  const initialSize = window.innerWidth <= 768 ? '36px' : '24px';

  // Create a state for the icon size
  const [iconSize, setIconSize] = useState(initialSize);

  // Update icon size when window is resized
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIconSize('36px');
      } else {
        setIconSize('24px');
      }
    };

    window.addEventListener('resize', handleResize);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <Link
        href="https://discord.gg/dgaSZr5qdw"
        isExternal
        _hover={{ textDecoration: "none", color: "white" }}
      >
        <Box
          top="0"
          left="0"
          right="0"
          width="100%"
          bg={useColorModeValue("purple.600", "purple.700")}
          color="white"
          p={2}
          textAlign="center"
          zIndex="1000"
          mb="10px"
          borderRadius="5px"
        >
          <Flex alignItems="center" justifyContent="center">
          <FaDiscord style={{ margin: "8px", fontSize: iconSize }} />
            Join Discord for discussion about next features and report bugs.
          </Flex>
        </Box>
      </Link>
      {loading && (
        <Flex align="center" justify="center" minHeight="10vh">
          <Spinner size="xl" />
        </Flex>
      )}
      {isErrorDialogOpen && (
        <Alert
          status="warning"
          mb="20px"
          w="-webkit-fit-content"
          alignItems="center"
          mt="20px"
          borderRadius="10px"
          mr="20px"
          ml="20px"
        >
          <AlertIcon />
          <AlertDescription>
          Experimental tool, but with your help, it can be useful! Join our Discord for feedback & bug reports.
          </AlertDescription>
        </Alert>
      )}
      {data && data.artist && data.artist.address.startsWith("kt") && (
          <Alert
            status="warning"
            mb="20px"
            w="-webkit-fit-content"
            alignItems="center"
            mt="20px"
            borderRadius="10px"
            mr="20px"
            ml="20px"
          >
            <AlertIcon />
            <AlertDescription>
            This collection was not minted on objkt, so the data is not complete.
            </AlertDescription>
          </Alert>
        )}
        {data && data.contractAddress && data.contractAddress === "KT1LjmAdYQCLBjwv4S2oFkEzyHVkomAf5MrW" && (
          <Alert
            status="warning"
            mb="20px"
            w="-webkit-fit-content"
            alignItems="center"
            mt="20px"
            borderRadius="10px"
            mr="20px"
            ml="20px"
          >
            <AlertIcon />
            <AlertDescription>
            This artwork was not minted on objkt, so the data is not complete.
            </AlertDescription>
          </Alert>
        )}
      <Flex direction={{ base: "column", md: "column", xl: "row" }}
      alignItems={{ base: 'center', md: 'center', xl: 'stretch' }}>
        <Flex
          direction="column"
          m="20px"
          width={{ base: "90vw", md: "90vw", xl: "25vw" }}
        >
          <Text
            marginLeft="2rem"
            marginBottom="1rem"
            fontSize="x-large"
            fontWeight="900"
          >
            Collection Details
          </Text>
          <ButtonGroup variant="outline" spacing="6" m="10px">  
      <Flex
          direction="row"
          width={{ base: "90vw", md: "45vw", xl: "45vw" }}
          sx={{
            base: { width: "-webkit-fill-available" },
            md: { width: "auto" },
            xl: { width: "auto" },
          }}
          justifyContent={{ base: "space-evenly", md: "space-evenly", xl: "start" }}
        >
     
     <Button 
          onClick={() => handleRangeChange('all')}
          style={range === 'all' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          m={{ base: "unset", md: "unset", xl: "10px" }}
        >
          All
        </Button>
        <Button 
          onClick={() => handleRangeChange('30d')}
          style={range === '30d' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          // disabled={true}
          m={{ base: "unset", md: "unset", xl: "10px" }}
        >
          30 Days
        </Button>
        <Button 
          onClick={() => handleRangeChange('7d')}
          style={range === '7d' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          // disabled={true}
          m={{ base: "unset", md: "unset", xl: "10px" }}
        >
          7 Days
        </Button>
        <Button 
          onClick={() => handleRangeChange('24h')}
          style={range === '24h' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          m={{ base: "unset", md: "unset", xl: "10px" }}  
        >
          24 Hours
        </Button>
      
      </Flex>
      </ButtonGroup>
          <MemoizedStorage
            imageUrl={data?.imageUrl}
            name={data?.name}
            description={data?.description}
            twitter={data?.twitter}
            website={data?.website}
            artist={data?.artist}
            collaborators={data?.collaborators}
            collectionAddress={data?.contractAddress}
          />
          <MemoizedGeneral
            category={data?.category}
            collectionType={data?.collectionType}
            totalArts={data?.totalArts}
            totalEditions={data?.totalEditions}
            totalBurned={data?.totalBurned}
            firstMintTime={data?.firstMintTime}
            lastUpdate={data?.lastUpdated}
          />
          <Auction
            successAuctions={data?.successAuctions}
            failedAuctions={data?.failedAuctions}
            avgReserveAuction={data?.avgReserveAuction}
            avgSettleAuction={data?.avgSettleAuction}
          />
        </Flex>

        <Flex
          direction="column"
          m="20px"
          width={{ base: "90vw", md: "90vw", xl: "40vw" }}
        >
          <SellStats
            totalVol={
              data
                ? parseInt(data.totalPrimarySell) +
                  parseInt(data.totalSecondarySell)
                : 0
            }
            totalPrimarySell={data ? data.totalPrimarySell : 0}
            totalSecondarySell={data ? data.totalSecondarySell : 0}
            totalProfitAmount={data ? data.totalProfitAmount : 0}
            avgProfitAmount={data ? data.avgProfitAmount : 0}
            avgProfitPercentage={data ? data.avgProfitPercentage : 0}
            secondaryCount={data ? data.totalSecondaryCount : 0}
            primaryCount={data ? data.totalPrimaryCount : 0}
            primaryAvgDeal={data ? data.primaryAvgDeal : 0}
            secondaryAvgDeal={data ? data.secondaryAvgDeal : 0}
            avgDeal={data ? data.avgDeal : 0}
          />
          <Chart
            lastDeal={data?.lastDeal}
            saleHistory={data?.saleHistory}
            offerHistory={data?.offerHistory}
            auctionHistory={data?.auctionHistory}
            range={range}
          />
          <Collectors
            collectorsList={data?.collectorsList}
            totalHolders={data?.totalHolders}
            totalCollectors={data?.totalCollectors}
          />
        </Flex>

        <Flex
          direction="column"
          m="20px"
          width={{ base: "90vw", md: "90vw", xl: "25vw" }}
        >
          <ListToSell
            primaryListToSellTime={data?.primaryListToSellTime}
            secondaryListToSellTime={data?.secondaryListToSellTime}
          />
          <SellWays
            totalListSold={data?.totalListSold}
            totalOESold={data?.totalOESold}
            totalOfferSold={data?.totalOfferSold}
            successAuctions={data?.successAuctions}
          />
        </Flex>
      </Flex>
      {/* <Flex direction="column">
        <Flex
          mt="45px"
          mb="20px"
          justifyContent="start"
          direction={{ base: "column", md: "row" }}
          align={{ base: "start", md: "center" }}
        >
          <Text color={textColor} fontSize="2xl" ms="24px" fontWeight="700">
            Top Arts by
          </Text>
          <Flex
            align="center"
            me="20px"
            ml="20px"
            ms={{ base: "24px", md: "0px" }}
            mt={{ base: "20px", md: "0px" }}
          >
            <Link
              ml="20px"
              color={textColorBrand}
              fontWeight="500"
              me={{ base: "34px", md: "44px" }}
              to="#art"
            >
              Spend
            </Link>
            <Link
              color={textColorBrand}
              fontWeight="500"
              me={{ base: "34px", md: "44px" }}
              to="#music"
            >
              Profit
            </Link>
          </Flex>
        </Flex>

        <Flex wrap="wrap" justify="start" mb={{ base: "20px", xl: "0px" }} width={{ base: "90vw", md: "90vw", xl: "25vw" }}>
          <Box mr="20px" mb="20px">
            <Art
              name="Art Name"
              image={Nft1}
              avgProfit="110 Xtz"
              avgList="29d and 2h"
              collectors='23'
            />
          </Box>
          <Box mr="20px" mb="20px">
            <Art
              name="Art Name"
              image={Nft2}
              avgProfit="110 Xtz"
              avgList="29d and 2h"
              collectors='23'
            />
          </Box>
          <Box mb="20px">
            <Art
              name="Art Name"
              image={Nft3}
              avgProfit="110 Xtz"
              avgList="29d and 2h"
              collectors='23'
            />
          </Box>
        </Flex>
        </Flex> */}
    </Box>
  );
}
