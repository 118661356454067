// Chakra imports
import { Checkbox, Select, Box, Input, Button, ButtonGroup, useColorModeValue, Flex, Link, Spinner } from "@chakra-ui/react";
import DevelopmentTable from "views/admin/artTable/components/DevelopmentTable";
import React from "react";
import { useState, useEffect  } from 'react';
import { CgCornerDownLeft } from "react-icons/cg";
import { FaDiscord } from 'react-icons/fa';
import ReactGA from 'react-ga';
import { useHistory } from "react-router-dom";

// import { columnsDataDevelopment } from "views/admin/dataTables/variables/columnsData";

const columnsDataDevelopment = [
  {
    Header: "Art Name",
    accessor: "artName",
    disableSortBy: true,
  },
  {
    Header: "Artist",
    accessor: "artist",
    disableSortBy: true,
  },
  {
    Header: "Editions",
    accessor: "editions",
  },
  {
    Header: "Average Price",
    accessor: "avgDeal",
  },
  {
    Header: "Primary Count",
    accessor: "primaryCount",
  },
  {
    Header: "Primary Sell",
    accessor: "totalPrimarySell",
    disableSortBy: true,
  },
  {
    Header: "Secondary Count",
    accessor: "secondaryCount",
  },
  {
    Header: "Secondary Sell",
    accessor: "totalSecondarySell",
  },
  
  {
    Header: "Avg. Secondary Profit",
    accessor: "avgProfitAmount",
  },
  {
    Header: "Avg. Secondary Profit %",
    accessor: "avgProfitPercentage",
  },
  {
    Header: "Collectors",
    accessor: "totalCollectors",
  },
  {
    Header: "Last Deal",
    accessor: "lastDeal",
  },
  {
    Header: "Age",
    accessor: "mintTime",
  },
  {
    accessor: "artImage",
  },
  {
    accessor: "artistImage",
  },
  {
    accessor: "tokenId",
  },
  {
    accessor: "artistWallet",
  },
  {
    accessor: "contractAddress",
  },
];

const calculateAge = (mintTime) => {
  const mintDate = new Date(mintTime);
  const now = new Date();
  const diffTime = Math.abs(now - mintDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // diff in days
  
  if (diffDays < 30) {
      return `${diffDays} day${diffDays > 1 ? 's' : ''}`;
  } else if (diffDays < 365) {
      const diffMonths = Math.floor(diffDays / 30);
      const remainingDays = diffDays % 30;
      return `${diffMonths}M${remainingDays > 0 ? ' and '+remainingDays+'d' : ''}`;
  } else {
      const diffYears = Math.floor(diffDays / 365);
      const remainingMonths = Math.floor((diffDays % 365) / 30);
      return `${diffYears}Y${remainingMonths > 0 ? ' and '+remainingMonths+'d' : ''}`;
  }
}


function formatAmount(amount, unit) {
  const parsedAmount = parseInt(amount) / 1000000;
  let formattedAmount;

  if (parsedAmount === 0) {
    return '-';
  } else if (parsedAmount < 1) {
    formattedAmount = parseFloat(parsedAmount.toFixed(2));
  } else if (parsedAmount >= 1 && parsedAmount < 10) {
    formattedAmount = parseFloat(parsedAmount.toFixed(1));
  } else {
    formattedAmount = parseFloat(parsedAmount.toFixed(0));
  }

  return `${formattedAmount.toLocaleString()} ${unit}`;
}

export default function Settings() {
  const [range, setRange] = useState('24h');
  const [tableDataDevelopment, setTableDataDevelopment] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [oneOfOneEdition, setOneOfOneEdition] = useState(false); // Added filter state
  const [secondaryCount, setSecondaryCount] = useState(false); // Added filter state
  const [sort, setSort] = useState(''); // Added sort state
  const itemsPerPage = 20;
  const [loading, setLoading] = useState(true);
  
  const history = useHistory();
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  
    // Listen for changes
    return history.listen(location => {
      ReactGA.pageview(location.pathname + location.search);
    });
  }, [history]);

  const handleRangeChange = async (newRange) => {
    setLoading(true);
    setRange(newRange);
    let data;
    try {
      let fetchURL = `https://api.pedrodig.xyz/arts/${newRange}?limit=${itemsPerPage}&page=${currentPage}`;

    // append optional parameters to fetch URL if they are present
    if (oneOfOneEdition) {
      fetchURL += `&editions=1`;
    } 
    if (secondaryCount) {
      fetchURL += `&secondaryCount=${secondaryCount}`;
    }
    if (sort) {
      fetchURL += `&sort=${sort}`;
    }
console.log(fetchURL)
    const response = await fetch(fetchURL, {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY
      }
    });
      const mid = await response.json();

      data = mid.map(item => {
        return {
          artImage: item.artImage.length > 0 ? item.artImage[0].url : "ipfs://",
          artName: item.artName,
          artistImage: item.artist ? item.artist : "ipfs://",
          artist: item.artist ? (item.artist.alias || `${item.artist.address.slice(0, 5)}...${item.artist.address.slice(-5)}`) : "-",
          editions: item.editions,
          tokenId: item.tokenId,
          artistWallet: item.artist.address,
          contractAddress: item.contractAddress,
          avgDeal: (item.avgDeal || item.avgDeal === 0) ? formatAmount(item.avgDeal, 'Xtz') : "-",
primaryCount: item.primaryCount || "-",
secondaryCount: item.secondaryCount || "-",
avgProfitPercentage: parseInt(item.avgProfitPercentage) === 0
  ? "-"
  : (parseInt(item.avgProfitPercentage) < 1
    ? (parseFloat(item.avgProfitPercentage)).toFixed(2).toString() + "%"
    : (parseInt(item.avgProfitPercentage)).toFixed(0).toString() + "%"),
totalPrimarySell: formatAmount(item.totalPrimarySell, 'Xtz'),
totalSecondarySell: formatAmount(item.totalSecondarySell, 'Xtz'),
avgProfitAmount: formatAmount(item.avgProfitAmount, 'Xtz'),

      
          totalCollectors: item.totalCollectors,
          lastDeal: item.lastDeal ? item.lastDeal.timestamp : 0,
          mintTime: calculateAge(item.mintTime),
        };
      });
      console.log(range, mid)
      setTableDataDevelopment(data);
      setLoading(false);
      } catch (error) {
      console.error('Error fetching art data:', error);
      }
      };
      

  useEffect(() => {
    handleRangeChange(range);
  }, [currentPage, oneOfOneEdition, secondaryCount, sort]); // Added currentPage to the dependency array

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage > 1 ? prevPage - 1 : prevPage);
  };

  const handlePageChange = (event) => {
    setLoading(true);
    const pageNum = Number(event.target.value);
    if (!isNaN(pageNum) && pageNum > 0) {
      setCurrentPage(pageNum);
    }
    setLoading(false);
  };

  const handleOneOfOneEditionChange = (e) => {
    setLoading(true);
    setOneOfOneEdition(e);
    setLoading(false);
  };

  const handleSecondaryCount = (e) => {
    setLoading(true);
    setSecondaryCount(e);
    setLoading(false);
  };

  const handleSortChange = (e) => {
    setLoading(true);
    setSort(e);
    setLoading(false);
  };


  const initialSize = window.innerWidth <= 768 ? '36px' : '24px';

  // Create a state for the icon size
  const [iconSize, setIconSize] = useState(initialSize);

  // Update icon size when window is resized
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIconSize('36px');
      } else {
        setIconSize('24px');
      }
    };

    window.addEventListener('resize', handleResize);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <Flex direction={{ base: "column", md: "column", xl: "row" }} justify='space-between'>
      
 
</Flex>
<Link href="https://discord.gg/dgaSZr5qdw" isExternal _hover={{ textDecoration: 'none', color: 'white' }} >
  <Box
    top="0"
    left="0"
    right="0"
    width="100%"
    bg={useColorModeValue("purple.600", "purple.700")}
    color="white"
    p={2}
    textAlign="center"
    zIndex="1000"
    mb='10px'
    borderRadius='5px'
>
    <Flex alignItems="center" justifyContent="center">
    <FaDiscord style={{ margin: "8px", fontSize: iconSize }} />
    Experimental tool, but with your help, it can be useful! Join our Discord for feedback & bug reports.
    </Flex>
</Box>
</Link>
{loading && (
          <Flex align="center" justify="center" minHeight="10vh">
            <Spinner size="xl" />
          </Flex>
        )}
<DevelopmentTable
      columnsData={columnsDataDevelopment}
      tableData={tableDataDevelopment}
      setOneOfOneEdition={handleOneOfOneEditionChange}
      setSort={handleSortChange}
      setSecondaryCount={handleSecondaryCount}
      oneOfOneEdition={oneOfOneEdition}
      sort={sort}
      secondaryCount={secondaryCount}
      currentPage={currentPage}
      handlePageChange={handlePageChange}
      handleNextPage={handleNextPage}
      handlePreviousPage={handlePreviousPage}
      itemsPerPage={itemsPerPage}
      handleRangeChange={handleRangeChange}
      range={range}
    />

    </Box>
  );
}
