import {
  Avatar,
  Badge,
  Box,
  Link,
  Button,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  css, 
  useTheme
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Informationl2s from "views/admin/art/components/Informationl2s";
import Card from "components/card/Card.js";

const tableColumnsTopCreators = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Total Spend",
    accessor: "totalSpend",
  },
  {
    Header: "Profit",
    accessor: "profit",
  },
  {
    accessor: "image",
  },

];

const calcPrice = (value, defaultReturn = "-") => {
  if (!value) {
    return defaultReturn;
  }
  const deal = parseInt(value) / 1000000;
  if (deal < 1) {
    return parseFloat(deal.toFixed(2)).toString() + " Xtz";
  } else if (deal >= 1 && deal < 10) {
    return parseFloat(deal.toFixed(1)).toString() + " Xtz";
  } else {
    return deal.toFixed(0) + " Xtz";
  }
};

const ipfsToHttp = (ipfsUrl) => {
  if (ipfsUrl) {
    return ipfsUrl.replace('ipfs://', 'https://ipfs.io/ipfs/');
  }
  return null;
}

function Collectors(props) {
  const { collectorsList, name, ...rest } = props;

  const data = useMemo(() => collectorsList ? collectorsList.map((collector) => ({
    image: collector.contractImage ? ipfsToHttp(collector.contractImage) : (collector.artistImage ? ipfsToHttp(collector.artistImage) : "ipfs://") ,
    name: collector.contractName ? collector.contractName : (collector.artistName ? collector.artistName : (collector.artistWallet ? `${collector.artistWallet.slice(0, 5)}...${collector.artistWallet.slice(-5)}` : "-")),
    totalSpend: parseFloat(collector.spent),
    profit: parseFloat(collector.profit),
  })) : [], [collectorsList]);
  console.log("collectorsListcollectorsList",collectorsList)
  
  const columns = useMemo(() => tableColumnsTopCreators.filter(column => column.accessor !== "image"), [tableColumnsTopCreators]);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
    const theme = useTheme();

    const ScrollbarCSS = css({
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-track": {
        background: theme.colors.gray[200], // match to your theme colors
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.colors.gray[500], // match to your theme colors
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: theme.colors.gray[700], // match to your theme colors
      },
    });
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest} mt='20px'>
   
      <Flex
      maxHeight='500px'
      {...rest}
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}
        css={ScrollbarCSS}>
          
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify='space-between'
          w='100%'
          px='22px'
          pb='20px'

          boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
          <Text color={textColor} fontSize='xl' fontWeight='600'>
            {name}
          </Text>
          
        </Flex>
        <Flex
        
        mb='20px'
        justifyContent='space-between'
        direction={{ base: "column", md: "row" }}
        align={{ base: "start", md: "center" }
      }> 

      </Flex>
        <Table {...getTableProps()} variant='simple' color='gray.500'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor='transparent'>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Name") {
                      data = (
                        <Flex align='center'>
                          <Avatar
                            src={row.original.image}
                            w='30px'
                            h='30px'
                            me='8px'
                          />
                          <Text
                            color={textColor}
                            fontSize='sm'
                            fontWeight='600'>
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    }
                     else if (cell.column.Header === "Total Spend") {
                      data = (
                        <Text
                          color={textColorSecondary}
                          fontSize='sm'
                          fontWeight='500'>
                          {calcPrice(parseInt(cell.value))} 
                        </Text>
                      );
                    } else if (cell.column.Header === "Profit") {
                      data = (
                        <Text
                          color={textColorSecondary}
                          fontSize='sm'
                          fontWeight='500'>
                          {calcPrice(parseInt(cell.value))} 
                        </Text>
                      );
                    } 
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Flex>
      
    
    </Card>
  );
}

export default Collectors;
