import {
  Avatar,
  Box,
  Button,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  css, 
  useTheme
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Informationl2s from "views/admin/collection/components/Informationl2s";
import Card from "components/card/Card.js";


function Collectors(props) {
  const { ...rest } = props;
  const columns = useMemo(
    () => [
      {
        Header: "Candidate Feature",
        accessor: "feature",
      },
    ],
    []
  );
  
  const data = useMemo(
    () => [
      { feature: "(done ✓) Collector Insights: Detailed breakdowns of collections, top artist affiliations, and profits derived from various artworks." },
      { feature: "Chrome Extension for Objkt: Develop a chrome extension that integrates with the objkt website. This extension would allow users to link each art, collection, or artist to PedroDig results." },
      { feature: "Homepage Highlights: Showcase the top 5 Artists, Collections, and Arts on the main page as 'hots'." },
      { feature: "Advanced Table Filters: Introduce advanced filtering options in tables. For instance, users should be able to filter artists by age (e.g., 3 to 5 months) and primary sales within a specified range (e.g., 500 to 2000 xtz)." },
      { feature: "Monthly Data Newsletter: Offer a detailed monthly view of market insights, leveraging the database visualization capabilities." },
      { feature: "Offer Data: Extract information about top offer placements." },
      { feature: "Artist Charts: Include minting history and transaction data over time for each artist." },
      { feature: "Artist Ratings: Rank artists based on factors like listing to sale time, secondary market profits, number of artworks, and artist tenure. This could lead to a 'top-rated' section on the homepage." },
      { feature: "Art Comments: Allow collectors to leave comments on art pages, with a sync to PedroDig wallets to verify ownership." },
      { feature: "New Artist Recognition: Develop a service to highlight returning or new artists with a limited number of artworks." },
      { feature: "Coin & DAO System: Implement a DAO where participants can stake Tezos (like 0.5xtz) to vote on new artist additions. The primary goal here would be to foster the growth of the Tezos artist community." },
      { feature: "Detect Artificial Hype: Identify artworks or collections that might be inflating prices through fake or high purchases. This would require a deep dive into data analytics." },
    ],
    []
  );
  

  const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = tableInstance;

  const theme = useTheme();
  const ScrollbarCSS = css({
    "&::-webkit-scrollbar": { width: "10px" },
    "&::-webkit-scrollbar-track": { background: theme.colors.gray[200] },
    "&::-webkit-scrollbar-thumb": { background: theme.colors.gray[500] },
    "&::-webkit-scrollbar-thumb:hover": { background: theme.colors.gray[700] },
  });
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const bg = useColorModeValue("white", "navy.700");

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest} mt='20px' >
      <Flex
      h='600px'
      {...rest}
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}
        css={ScrollbarCSS}
        >
          
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify='space-between'
          w='100%'
          px='22px'
          pb='20px'

          boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
          <Text color={textColor} fontSize="xl" fontWeight="600">
            Upcoming Features
          </Text>
          
        </Flex>
        <Flex
        
        mb='20px'
        justifyContent='space-between'
        direction={{ base: "column", md: "row" }}
        align={{ base: "start", md: "center" }
      }> 

        <Card bg={bg} m='10px'boxShadow={cardShadow} >
      <Box>
        <Text fontWeight='500' color={textColorSecondary} fontSize='m' align='center'>
        Please join our Discord to vote on upcoming features or to suggest new ones. You can also send me a direct message on Twitter.
        </Text>
      </Box>
    </Card>

      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" >
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor='transparent'>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        color="#dee8f5"
                        fontSize={{ sm: "18px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {cell.render("Cell")} {/* Render the cell's data */}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Flex>
      
    </Card>
  );
}

export default Collectors;
