import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";

export function NavMenuLinks({ routes }) {
  const location = useLocation();
  const activeColor = useColorModeValue("gray.700", "white");
  const textColor = useColorModeValue("secondaryGray.500", "white");
  const brandColor = useColorModeValue("brand.500", "brand.400");

  const activeRoute = (routeName) => location.pathname.toLowerCase() === routeName.toLowerCase();

// && route.name !== "/Collector" && route.name !== "/Artist" && route.name !== "/Collection" && route.name !== "/Art"
const createLinks = (routes) => {
  return routes.map((route, index) => {
    if (
      route.layout !== "/auth" &&
      route.name !== "Collector" &&
      route.name !== "Artist" &&
      route.name !== "Collection" &&
      route.name !== "Art"
    ) {
      const fullPath = route.path === "/" ? route.path : route.layout + route.path;
      return (
        <NavLink key={index} to={fullPath}>
          <HStack
            spacing={activeRoute(fullPath) ? "22px" : "26px"}
            py='5px'
            ps='10px'
            pr='10px'
            direction="row"
            alignItems="center"
            border={activeRoute(fullPath) ? '2px' : 'none'} 
            borderColor={activeRoute(fullPath) ? brandColor : 'transparent'}
            borderRadius='5px' 
          >
            <Text
              me='auto'
              color={
                activeRoute(fullPath)
                  ? activeColor
                  : textColor
              }
              fontWeight={
                activeRoute(fullPath) ? "bold" : "normal"
              }>
              {route.name}
            </Text>
          </HStack>
        </NavLink>
      );
    }
  });
};
return (
  <Flex direction="row">
    {createLinks(routes)}
  </Flex>
);

}

export default NavMenuLinks;
