import {
  Avatar,
  Badge,
  Box,
  Link,
  Button,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  css, 
  useTheme
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Informationl2s from "views/admin/art/components/Informationl2s";
import Card from "components/card/Card.js";

const tableColumnsTopCreators = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Number of Holds",
    accessor: "numberOfHolds",
  },
  {
    Header: "Total Spend",
    accessor: "totalSpend",
  },
  {
    Header: "Profit",
    accessor: "profit",
  },
  {
    Header: "Profit Percentage",
    accessor: "profitPercentage",
  },
  {
    accessor: "image",
  },
  {
    accessor: "wallet",
  }

];

const calcPrice = (value, defaultReturn = "-") => {
  if (!value) {
    return defaultReturn;
  }
  const deal = parseInt(value) / 1000000;
  if (deal < 1) {
    return parseFloat(deal.toFixed(2)).toString() + " Xtz";
  } else if (deal >= 1 && deal < 10) {
    return parseFloat(deal.toFixed(1)).toString() + " Xtz";
  } else {
    return deal.toFixed(0) + " Xtz";
  }
};

function Collectors(props) {
  const { collectorsList, ...rest } = props;

  const data = useMemo(() => collectorsList ? collectorsList.map((collector) => ({
    image: collector.logo ? collector.logo : "ipfs://",
    name: collector.name ? [collector.name, collector.logo] : [`${collector.wallet.slice(0, 5)}...${collector.wallet.slice(-5)}`, collector.logo],
    numberOfHolds: collector.numberOfHold,
    totalSpend: parseFloat(collector.totalSpend),
    profit: parseFloat(collector.totalProfit),
    profitPercentage: collector.totalProfit ? (collector.totalSpend ? (parseFloat(collector.totalProfit)/parseFloat(collector.totalSpend)) * 100 : '∞' ) : 0,
    wallet: collector.wallet,
    isFree: parseFloat(collector.totalSpend) === 0
  })) : [], [collectorsList]);
  
  
  const totalCollectors = collectorsList ? collectorsList.length : 0;

  // Compute the total number of holders with numberOfHolds > 0
  const totalHolders = collectorsList 
    ? collectorsList.filter(collector => collector.numberOfHold > 0).length 
    : 0;

  const columns = useMemo(() => tableColumnsTopCreators.filter(column => column.accessor !== "image" && column.accessor !== "wallet" && column.accessor !== "profitPercentage"), [tableColumnsTopCreators]);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
    const theme = useTheme();

    const ScrollbarCSS = css({
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-track": {
        background: theme.colors.gray[200], // match to your theme colors
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.colors.gray[500], // match to your theme colors
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: theme.colors.gray[700], // match to your theme colors
      },
    });
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest} mt='20px'>
   
      <Flex
      maxHeight='500px'
      {...rest}
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}
        css={ScrollbarCSS}>
          
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify='space-between'
          w='100%'
          px='22px'
          pb='20px'

          boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
          <Text color={textColor} fontSize='xl' fontWeight='600'>
            Collectors
          </Text>
          
        </Flex>
        <Flex
        
        mb='20px'
        justifyContent='space-between'
        direction={{ base: "column", md: "row" }}
        align={{ base: "start", md: "center" }
      }> 
<Informationl2s
          boxShadow={cardShadow}
          title='Total Collectors'
          value={totalCollectors ? totalCollectors : "-"}
          m='10px'
        />
        <Informationl2s
          boxShadow={cardShadow}
          title='Total Holders'
          value={totalHolders ? totalHolders : "-"}
        />
      </Flex>
        <Table {...getTableProps()} variant='simple' color='gray.500'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor='transparent'>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Name") {
                      data = (
                        <Flex align='center'>
                          <Avatar
                            src={row.original.image}
                            w='30px'
                            h='30px'
                            me='8px'
                          />
                          <Text
                          // href={`https://objkt.com/profile/${row.original.wallet}/created?utm_source=pedrodig&utm_medium=referral&utm_campaign=objkt`}
                            color={textColor}
                            // target="_blank" 
                            fontSize='sm'
                            fontWeight='600'>
                            {cell.value[0]}
                            {row.original.isFree && (
                              <Badge colorScheme="green" ml="1">
                                Free
                              </Badge>
                            )}
                          </Text>
                        </Flex>
                      );
                    }
                     else if (cell.column.Header === "Number of Holds") {
                      data = (
                        <Text
                          color={textColorSecondary}
                          fontSize='sm'
                          fontWeight='500'>
                          {cell.value < 0 ? 0 : cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "Total Spend") {
                      data = (
                        <Text
                          color={textColorSecondary}
                          fontSize='sm'
                          fontWeight='500'>
                          {calcPrice(parseInt(cell.value))} 
                        </Text>
                      );
                    } else if (cell.column.Header === "Profit") {
                      data = (
                        <Text
                          color={textColorSecondary}
                          fontSize='sm'
                          fontWeight='500'>
                          {calcPrice(parseInt(cell.value))} 
                        </Text>
                      );
                    } else if (cell.column.Header === "Profit Percentage") {
                      data = (
                        <Text
                          color={textColorSecondary}
                          fontSize='sm'
                          fontWeight='500'>
                          {parseInt(cell.value) !== 0 ? (cell.value === '∞' ? '∞' : parseInt(cell.value).toFixed(0).toString() + "%" ): "-"} 
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Flex>
      
    
    </Card>
  );
}

export default Collectors;
