// Chakra imports
import { Box, Flex, Link, useColorModeValue, Spinner } from "@chakra-ui/react";
import DevelopmentTable from "views/admin/artistTable/components/DevelopmentTable";
import React from "react";
import { useState, useEffect } from "react";
import { FaDiscord } from "react-icons/fa";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";

const columnsDataDevelopment = [
  {
    Header: "Artist",
    accessor: "artist",
    disableSortBy: true,
  },
  {
    Header: "Collections",
    accessor: "collectionsAddreses",
  },
  {
    Header: "Average Price",
    accessor: "avgDeal",
  },
  {
    Header: "Avg Primary Price",
    accessor: "primaryAvgDeal",
  },
  {
    Header: "Avg Secondary Price",
    accessor: "secondaryAvgDeal",
  },
  {
    Header: "Primary Count",
    accessor: "totalPrimaryCount",
  },
  {
    Header: "Primary Sell",
    accessor: "totalPrimarySell",
  },

  {
    Header: "Primary Sell Speed",
    accessor: "primaryListToSellTime",
  },
  {
    Header: "Secondary Count",
    accessor: "totalSecondaryCount",
  },
  {
    Header: "Secondary Sell",
    accessor: "totalSecondarySell",
  },
  {
    Header: "Secondary Sell Speed",
    accessor: "secondaryListToSellTime",
  },

  {
    Header: "Avg. Secondary Profit",
    accessor: "avgProfitAmount",
  },
  {
    Header: "Avg. Secondary Profit %",
    accessor: "avgProfitPercentage",
  },
  {
    Header: "Collectors",
    accessor: "totalCollectors",
  },
  {
    Header: "Last Deal",
    accessor: "lastDeal",
  },
  {
    Header: "Age",
    accessor: "firstMintTime",
  },
  {
    accessor: "artImage",
  },
  {
    accessor: "artistImage",
  },
  {
    accessor: "website",
  },
  {
    accessor: "twitter",
  },
  {
    accessor: "arts",
  },
  {
    accessor: "editions",
  },
  {
    accessor: "minPrimaryLToS",
  },
  {
    accessor: "maxPrimaryLToS",
  },
  {
    accessor: "minSecondaryLToS",
  },
  {
    accessor: "maxSecondaryLToS",
  },
  {
    accessor: "artistWallet",
  },
];
const calculateAge = (mintTime) => {
  const mintDate = new Date(mintTime);
  const now = new Date();
  const diffTime = Math.abs(now - mintDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // diff in days

  if (diffDays < 30) {
    return `${diffDays} day${diffDays > 1 ? "s" : ""}`;
  } else if (diffDays < 365) {
    const diffMonths = Math.floor(diffDays / 30);
    const remainingDays = diffDays % 30;
    return `${diffMonths}M${
      remainingDays > 0 ? " and " + remainingDays + "d" : ""
    }`;
  } else {
    const diffYears = Math.floor(diffDays / 365);
    const remainingMonths = Math.floor((diffDays % 365) / 30);
    return `${diffYears}Y${
      remainingMonths > 0 ? " and " + remainingMonths + "d" : ""
    }`;
  }
};

const calculateRelativeTime = (mintTime) => {
  const diffSeconds = Math.abs(mintTime);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffSeconds / (60 * 60));
  const diffDays = Math.floor(diffSeconds / (60 * 60 * 24));
  const diffWeeks = Math.floor(diffSeconds / (60 * 60 * 24 * 7));
  const diffMonths = Math.floor(diffSeconds / (60 * 60 * 24 * 30)); // Approximation

  if (diffSeconds === 0) {
    return "-";
  } else if (diffSeconds < 60) {
    return `${diffSeconds}s`;
  } else if (diffMinutes < 60) {
    return `${diffMinutes}m`;
  } else if (diffHours < 24) {
    const remainingMinutes = diffMinutes % 60;
    return remainingMinutes === 0 ? `${diffHours}h` : `${diffHours}h and ${remainingMinutes}m`;
  } else if (diffDays < 7) {
    const remainingHours = diffHours % 24;
    return remainingHours === 0 ? `${diffDays}d` : `${diffDays}d and ${remainingHours}h`;
  } else if (diffWeeks < 4) {
    const remainingDays = diffDays % 7;
    return remainingDays === 0 ? `${diffWeeks}w` : `${diffWeeks}w and ${remainingDays}d`;
  } else {
    const remainingWeeks = diffWeeks % 4;
    // Ensuring that if diffMonths is 0, we return the weeks instead
    return (diffMonths === 0 && remainingWeeks === 0) 
      ? "0" 
      : (diffMonths === 0 
        ? `${remainingWeeks}w` 
        : (remainingWeeks === 0 
          ? `${diffMonths}M` 
          : `${diffMonths}M and ${remainingWeeks}w`));
  }
};




function formatAmount(amount, unit) {
  const parsedAmount = parseInt(amount) / 1000000;
  let formattedAmount;

  if (parsedAmount === 0) {
    return "-";
  } else if (parsedAmount < 1) {
    formattedAmount = parseFloat(parsedAmount.toFixed(2));
  } else if (parsedAmount >= 1 && parsedAmount < 10) {
    formattedAmount = parseFloat(parsedAmount.toFixed(1));
  } else {
    formattedAmount = parseFloat(parsedAmount.toFixed(0));
  }

  return `${formattedAmount.toLocaleString()} ${unit}`;
}

export default function Settings() {
  const [range, setRange] = useState("24h");
  const [tableDataDevelopment, setTableDataDevelopment] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [oneOfOneEdition, setOneOfOneEdition] = useState(false); // Added filter state
  const [secondaryCount, setSecondaryCount] = useState(false); // Added filter state
  const [sort, setSort] = useState(""); // Added sort state
  const itemsPerPage = 20;
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Listen for changes
    return history.listen((location) => {
      ReactGA.pageview(location.pathname + location.search);
    });
  }, [history]);

  const handleRangeChange = async (newRange) => {
    setLoading(true);
    setRange(newRange);
    let data;
    try {
      let fetchURL = `https://api.pedrodig.xyz/artists/${newRange}?limit=${itemsPerPage}&page=${currentPage}`;

      if (secondaryCount) {
        fetchURL += `&secondaryCount=${secondaryCount}`;
      }
      if (sort) {
        fetchURL += `&sort=${sort}`;
      }

      const response = await fetch(fetchURL, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      });
      let mid = await response.json();

      if (oneOfOneEdition) {
        console.log("oneOfOneEdition", mid);
        mid = mid.filter(
          (item) => parseInt(item.totalEditions) === parseInt(item.totalArts)
        );
      }

      data = mid.map((item) => {
        return {
          artist: item.name
            ? item.name ||
              `${item.artistWallet.slice(0, 5)}...${item.artistWallet.slice(
                -5
              )}`
            : "-",
          artistImage: item.imageUrl ? item.imageUrl : "ipfs://",
          website: item.website ? item.website : "nan",
          twitter: item.twitter ? item.twitter : "nan",
          arts: item.totalArts,
          editions: item.totalEditions,
          artistWallet: item.artistWallet,
          collectionsAddreses: item.collectionsAddreses
            ? item.collectionsAddreses.length
            : 111,
          avgDeal:
            item.avgDeal || item.avgDeal === 0
              ? formatAmount(item.avgDeal, "Xtz")
              : "-",

          primaryAvgDeal: formatAmount(item.primaryAvgDeal, "Xtz"),

          secondaryAvgDeal: formatAmount(item.secondaryAvgDeal, "Xtz"),

          totalPrimaryCount: item.totalPrimaryCount || "-",
          totalSecondaryCount: item.totalSecondaryCount || "-",
          avgProfitPercentage:
            parseInt(item.avgProfitPercentage) === 0
              ? "-"
              : parseInt(item.avgProfitPercentage) < 1
              ? parseFloat(item.avgProfitPercentage).toFixed(2).toString() + "%"
              : parseInt(item.avgProfitPercentage).toFixed(0).toString() + "%",

          totalPrimarySell: formatAmount(item.totalPrimarySell, "Xtz"),

          totalSecondarySell: formatAmount(item.totalSecondarySell, "Xtz"),

          avgProfitAmount: formatAmount(item.avgProfitAmount, "Xtz"),

          primaryListToSellTime:
            item.primaryListToSellTime.Avg !== 0
              ? calculateRelativeTime(item.primaryListToSellTime.Avg)
              : "-",
          minPrimaryLToS:
            item.primaryListToSellTime.min !== 0
              ? calculateRelativeTime(item.primaryListToSellTime.min)
              : "-",
          maxPrimaryLToS:
            item.primaryListToSellTime.max !== 0
              ? calculateRelativeTime(item.primaryListToSellTime.max)
              : "-",
          secondaryListToSellTime:
            item.primaryListToSellTime.Avg !== 0
              ? calculateRelativeTime(item.primaryListToSellTime.Avg)
              : "-",
          minSecondaryLToS:
            item.primaryListToSellTime.min !== 0
              ? calculateRelativeTime(item.primaryListToSellTime.min)
              : "-",
          maxSecondaryLToS:
            item.primaryListToSellTime.max !== 0
              ? calculateRelativeTime(item.primaryListToSellTime.max)
              : "-",

          totalCollectors: item.totalCollectors,
          lastDeal: item.lastDeal ? item.lastDeal.timestamp :  0 ,
          firstMintTime: calculateAge(item.firstMintTime),
        };
      });
      console.log(range, data);
      setTableDataDevelopment(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching art data:", error);
    }
  };

  useEffect(() => {
    handleRangeChange(range);
  }, [currentPage, oneOfOneEdition, secondaryCount, sort]); // Added currentPage to the dependency array

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const handlePageChange = (event) => {
    setLoading(true);
    const pageNum = Number(event.target.value);
    if (!isNaN(pageNum) && pageNum > 0) {
      setCurrentPage(pageNum);
    }
    setLoading(false);
  };

  const handleOneOfOneEditionChange = (e) => {
    setLoading(true);
    setOneOfOneEdition(e);
    setLoading(false);
  };

  const handleSecondaryCount = (e) => {
    setLoading(true);
    setSecondaryCount(e);
    setLoading(false);
  };

  const handleSortChange = (e) => {
    setLoading(true);
    setSort(e);
    setLoading(false);
  };

  const initialSize = window.innerWidth <= 768 ? '36px' : '24px';

  // Create a state for the icon size
  const [iconSize, setIconSize] = useState(initialSize);

  // Update icon size when window is resized
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIconSize('36px');
      } else {
        setIconSize('24px');
      }
    };

    window.addEventListener('resize', handleResize);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <Flex
        direction={{ base: "column", md: "column", xl: "row" }}
        justify="space-between"
      ></Flex>
      <Link
        href="https://discord.gg/dgaSZr5qdw"
        isExternal
        _hover={{ textDecoration: "none", color: "white" }}
      >
        <Box
          top="0"
          left="0"
          right="0"
          width="100%"
          bg={useColorModeValue("purple.600", "purple.700")}
          color="white"
          p={2}
          textAlign="center"
          zIndex="1000"
          mb="10px"
          borderRadius="5px"
        >
          <Flex alignItems="center" justifyContent="center">
          <FaDiscord style={{ margin: "8px", fontSize: iconSize }} />
          Experimental tool, but with your help, it can be useful! Join our Discord for feedback & bug reports.
          </Flex>
        </Box>
      </Link>

      {loading && (
          <Flex align="center" justify="center" minHeight="10vh">
            <Spinner size="xl" />
          </Flex>
        )}
      <DevelopmentTable
        columnsData={columnsDataDevelopment}
        tableData={tableDataDevelopment}
        setOneOfOneEdition={handleOneOfOneEditionChange}
        setSort={handleSortChange}
        setSecondaryCount={handleSecondaryCount}
        oneOfOneEdition={oneOfOneEdition}
        sort={sort}
        secondaryCount={secondaryCount}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        itemsPerPage={itemsPerPage}
        handleRangeChange={handleRangeChange}
        range={range}
      />
    </Box>
  );
}
