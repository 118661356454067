// Chakra imports
import { SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Information from "views/admin/collection/components/Information";
import Informationl2s from "views/admin/collection/components/Informationl2s";

const calcPrice = (value, defaultReturn = "-") => {
  if (!value) {
    return defaultReturn;
  }
  const deal = parseInt(value) / 1000000;
  if (deal < 1) {
    return parseFloat(deal.toFixed(2)).toString() + " Xtz";
  } else if (deal >= 1 && deal < 10) {
    return parseFloat(deal.toFixed(1)).toString() + " Xtz";
  } else {
    return deal.toFixed(0) + " Xtz";
  }
};

// Assets
export default function GeneralInformation(props) {
  const { successAuctions, failedAuctions, avgReserveAuction, avgSettleAuction, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest} >
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='xl'
        mt='10px'
        mb='20px'
        align= 'left'>
        Auctions Stats
      </Text>
      <SimpleGrid columns='2' gap='20px'>
        <Informationl2s
          boxShadow={cardShadow}
          title='Success Auctions'
          value={successAuctions ? successAuctions : "-"}
        />
        <Informationl2s
          boxShadow={cardShadow}
          title='Failed Auctions'
          value={failedAuctions ? failedAuctions : "-"}
        />
        <Informationl2s
          boxShadow={cardShadow}
          title='Avg Reserve price'
          value={avgReserveAuction ? calcPrice(parseInt(avgReserveAuction)) : "-"}
        />
        <Informationl2s
          boxShadow={cardShadow}
          title='Avg Settle price'
          value={avgSettleAuction ? calcPrice(parseInt(avgSettleAuction)) : "-"}
        />
      </SimpleGrid>
    </Card>
  );
}
