// chakra imports
import { Box, Flex, Stack, useColorModeValue } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import SidebarCard from "components/sidebar/components/SidebarCard";
import React from "react";
import { SearchBar } from 'components/navbar/searchBar/SearchBar';

// FUNCTIONS

function SidebarContent(props) {
  const { routes, secondary } = props;
  // SIDEBAR
  const navbarIcon = useColorModeValue('gray.400', 'white');
  return (
    <Flex direction='column' height='100%' pt='25px' px="16px" borderRadius='30px' >
      <Brand />
      <SearchBar mb={secondary ? { base: '10px', md: '20px' } : 'unset'} me="10px" borderRadius="30px" mt='10px' />

      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes} />
        </Box>
      </Stack>

      {/* <Box
        mt='60px'
        mb='40px'
        borderRadius='30px'>
        <SidebarCard />
      </Box> */}
    </Flex>
  );
}

export default SidebarContent;
