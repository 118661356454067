import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

export function SearchBar(props) {
  const history = useHistory();
  const { variant, background, children, placeholder, borderRadius, ...rest } = props;

  // State to hold the search term
  const [searchTerm, setSearchTerm] = useState("");

  // Chakra Color Mode
  const searchIconColor = useColorModeValue("gray.700", "white");
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");

  // Handle input change
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle search
  const handleSearch = () => {
    if (searchTerm.startsWith("tz")) {
      history.push(`/artist/${searchTerm}`);
    } else if (searchTerm.startsWith("KT")) {
      history.push(`/collection/${searchTerm}`);
    } else {
      alert("Please enter a valid contract or wallet address.");
    }
  };

  return (
    <InputGroup w={{ base: "100%", md: "200px" }} {...rest}>
      <InputLeftElement
        children={
          <IconButton
            bg='inherit'
            borderRadius='inherit'
            _hover='none'
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            icon={
              <SearchIcon color={searchIconColor} w='15px' h='15px' onClick={handleSearch}/>
            }
          />
        }
      />
      <Input
        value={searchTerm}
        onChange={handleChange}
        onKeyDown={(event) => event.key === 'Enter' && handleSearch()}
        variant='search'
        fontSize='sm'
        bg={background ? background : inputBg}
        color={inputText}
        fontWeight='500'
        _placeholder={{ color: "gray.400", fontSize: "14px" }}
        borderRadius={borderRadius ? borderRadius : "30px"}
        placeholder={placeholder ? placeholder : "Search..."}
      />
    </InputGroup>
  );
}
