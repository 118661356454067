// Chakra imports
import { Checkbox, Select, Box, Input, Button, ButtonGroup, useColorModeValue, Flex, Link, Spinner } from "@chakra-ui/react";
import DevelopmentTable from "views/admin/collectorTable/components/DevelopmentTable";
import React from "react";
import { useState, useEffect  } from 'react';
import { CgCornerDownLeft } from "react-icons/cg";
import { useHistory, useLocation } from 'react-router-dom';
import { FaDiscord } from 'react-icons/fa';
import ReactGA from 'react-ga';


// import { columnsDataDevelopment } from "views/admin/dataTables/variables/columnsData";

const columnsDataDevelopment = [
  {
    Header: "Collector Name",
    accessor: "collectorName",
    disableSortBy: true,
  },
  {
    Header: "Total Spent",
    accessor: "totalSpent",
  },
  {
    Header: "Avg. Spent For Each",
    accessor: "avgSpendEachArt",
  },
  {
    Header: "Total Sell",
    accessor: "totalSell",
  },
  {
    Header: "Total Art Collected",
    accessor: "totalArtCollect",
  },
  {
    Header: "Total Art Sell",
    accessor: "sellCount",
  },
  {
    Header: "Total Profit",
    accessor: "totalProfit",
  },
  
  {
    Header: "Avg. Profit For Each",
    accessor: "avgProfitAmount",
  },
  {
    Header: "Avg. Profit For Each %",
    accessor: "avgProfitPercentage",
  },
  {
    Header: "Last Buy",
    accessor: "lastBuy",
  },
  {
    accessor: "artistImage",
  },
  {
    accessor: "artistWallet",
  },
];
const calculateAge = (mintTime) => {
  const mintDate = new Date(mintTime);
  const now = new Date();
  const diffTime = Math.abs(now - mintDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // diff in days
  
  if (diffDays < 30) {
      return `${diffDays} day${diffDays > 1 ? 's' : ''}`;
  } else if (diffDays < 365) {
      const diffMonths = Math.floor(diffDays / 30);
      const remainingDays = diffDays % 30;
      return `${diffMonths}M${remainingDays > 0 ? ' and '+remainingDays+'d' : ''}`;
  } else {
      const diffYears = Math.floor(diffDays / 365);
      const remainingMonths = Math.floor((diffDays % 365) / 30);
      return `${diffYears}Y${remainingMonths > 0 ? ' and '+remainingMonths+'d' : ''}`;
  }
}


function formatAmount(amount, unit) {
  const parsedAmount = parseInt(amount) / 1000000;
  let formattedAmount;

  if (parsedAmount === 0) {
    return '-';
  } else if (parsedAmount < 1) {
    formattedAmount = parseFloat(parsedAmount.toFixed(2));
  } else if (parsedAmount >= 1 && parsedAmount < 10) {
    formattedAmount = parseFloat(parsedAmount.toFixed(1));
  } else {
    formattedAmount = parseFloat(parsedAmount.toFixed(0));
  }

  return `${formattedAmount.toLocaleString()} ${unit}`;
}

export default function Settings() {
  const [range, setRange] = useState('24h');
  const [tableDataDevelopment, setTableDataDevelopment] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [secondaryCount, setSecondaryCount] = useState(false); // Added filter state
  const [sort, setSort] = useState(''); // Added sort state
  const itemsPerPage = 20;
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  
  const history = useHistory();
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  
    // Listen for changes
    return history.listen(location => {
      ReactGA.pageview(location.pathname + location.search);
    });
  }, [history]);

  let data;
  const handleRangeChange = async (newRange) => {
    setLoading(true);
    setRange(newRange);
    
    try {
      let fetchURL = `https://api.pedrodig.xyz/collectors/${newRange}?limit=${itemsPerPage}&page=${currentPage}`;

    if (secondaryCount) {
      fetchURL += `&sellCount=${secondaryCount}`;
    }
    if (sort) {
      fetchURL += `&sort=${sort}`;
    }

    const response = await fetch(fetchURL, {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY
      }
    });
      const mid = await response.json();
      console.log("fetchURLfetchURL",fetchURL)
      console.log(range, mid)
      data = mid.map(item => {
        return {
          artistWallet: item.collectorWallet,
          artistImage: item.collectorImageUrl ? item.collectorImageUrl : "-",
          collectorName: item.collectorName ? item.collectorName : `${item.collectorWallet.slice(0, 5)}...${item.collectorWallet.slice(-5)}`,
          totalSpent: formatAmount(item.totalSpent, 'Xtz'),
          avgSpendEachArt: formatAmount(item.avgSpendEachArt, 'Xtz'),
          totalSell: formatAmount(item.totalSell, 'Xtz'),
          totalArtCollect: item.totalArtCollect ? item.totalArtCollect : "-",
          sellCount: item.sellCount ? item.sellCount : "-",
          totalProfit: formatAmount(item.totalProfit, 'Xtz'),
          avgProfitAmount: formatAmount(item.avgProfitAmount, 'Xtz'),
          avgProfitPercentage: parseInt(item.avgProfitPercentage) === 0
          ? "-"
          : (parseInt(item.avgProfitPercentage) < 1
            ? (parseFloat(item.avgProfitPercentage)).toFixed(2).toString() + "%"
            : (parseInt(item.avgProfitPercentage)).toFixed(0).toString() + "%"),

  
          lastBuy: item.lastBuy ? item.lastBuy : {timestamp: "-"},

        };
      });
      
      setTableDataDevelopment(data);
      setLoading(false);
      } catch (error) {
      console.error('Error fetching art data:', error);
      }
      };
      
  const setTableData = () => {
    if(data){
      setTableDataDevelopment(data);
    }
    
  }

  useEffect(() => {
    handleURLParameters();
    handleRangeChange(range);
    
  }, [currentPage, secondaryCount, sort]); // Added currentPage to the dependency array

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage > 1 ? prevPage - 1 : prevPage);
  };

  const handlePageChange = (event) => {
    setLoading(true);
    const pageNum = Number(event.target.value);
    if (!isNaN(pageNum) && pageNum > 0) {
      setCurrentPage(pageNum);
    }
    setLoading(false);
  };

  const handleURLParameters = () => {
    const currentParams = new URLSearchParams(location.search);
    const sortParam = currentParams.get("sort");
    const hasSecondaryParam = currentParams.get("hasSecondary");

    // Update component state
    if (sortParam) setSort(sortParam);
    if (secondaryCount !== false) {
      if (hasSecondaryParam) setSecondaryCount(hasSecondaryParam === "true");
    }
    

    // Update URL based on current state
    const newParams = {};
    if (sort) newParams.sort = sort;
    if (secondaryCount) newParams.hasSecondary = "true";
    history.replace({
      pathname: location.pathname,
      search: new URLSearchParams(newParams).toString(),
    });
  };

  const handleSecondaryCount = (e) => {
    setLoading(true);
    const isChecked = e;
    setSecondaryCount(isChecked);
    setLoading(false);

  };
  
  

  const handleSortChange = (e) => {
    setLoading(true);
    setSort(e);
    setLoading(false);
  };

  const initialSize = window.innerWidth <= 768 ? '36px' : '24px';

  // Create a state for the icon size
  const [iconSize, setIconSize] = useState(initialSize);

  // Update icon size when window is resized
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIconSize('36px');
      } else {
        setIconSize('24px');
      }
    };

    window.addEventListener('resize', handleResize);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <Flex direction={{ base: "column", md: "column", xl: "row" }} justify='space-between'>
      
 
</Flex>
<Link href="https://discord.gg/dgaSZr5qdw" isExternal _hover={{ textDecoration: 'none', color: 'white' }} >
  <Box
    top="0"
    left="0"
    right="0"
    width="100%"
    bg={useColorModeValue("purple.600", "purple.700")}
    color="white"
    p={2}
    textAlign="center"
    zIndex="1000"
    mb='10px'
    borderRadius='5px'
>
    <Flex alignItems="center" justifyContent="center">
    <FaDiscord style={{ margin: "8px", fontSize: iconSize }} />
    Experimental tool, but with your help, it can be useful! Join our Discord for feedback & bug reports.
    </Flex>
</Box>
</Link>
{loading && (
          <Flex align="center" justify="center" minHeight="10vh">
            <Spinner size="xl" />
          </Flex>
        )}
<DevelopmentTable
      columnsData={columnsDataDevelopment}
      tableData={tableDataDevelopment}
      setSort={handleSortChange}
      setSecondaryCount={handleSecondaryCount}
      sort={sort}
      secondaryCount={secondaryCount}
      currentPage={currentPage}
      handlePageChange={handlePageChange}
      handleNextPage={handleNextPage}
      handlePreviousPage={handlePreviousPage}
      itemsPerPage={itemsPerPage}
      handleRangeChange={handleRangeChange}
      range={range}
      
    />

    </Box>
  );
}
