// Chakra imports
import {  Avatar, Box, Flex, Image, useColorModeValue, Button } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import MiniStatistics from "components/card/MiniStatisticsGeneral";
import { useHistory } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';
import defaultImage from "assets/img/layout/pedrodig-image.png";

export default function Banner(props) {
  const { artImage, artist, colaboratorWallet, contract, contractImage, contractName, contractAddress, tokenId } = props;
  const objktLink = `https://objkt.com/asset/${contractAddress}/${tokenId}?utm_source=pedrodig&utm_medium=referral&utm_campaign=objkt`;

  const bgColorPrimary = useColorModeValue("#7551ff", "#7551ff");

  const ipfsToHttp = (ipfsUrl) => {
    if (ipfsUrl) {
      return ipfsUrl.replace('ipfs://', 'https://ipfs.io/ipfs/');
    }
    return null;
  }
  const history = useHistory();

  const handleError = (e) => {
    // Replace with your fallback image URL
    e.target.src = defaultImage; 
  };
  
  return (
    <Card mb={{ base: "20px", lg: "20px" }} align='center' >
      <Box
        borderRadius='16px'
        w='100%'
        h='auto' // Box height is now determined by the image
        overflow='hidden' // Make sure the borderRadius affects the image
      >
        <Image
          src={ipfsToHttp(artImage ? (artImage[0] ? artImage[0].url : "-") : "-")}

          alt='Art image'
          objectFit='cover'
          w='100%'
          h='100%'
          onError={handleError}
        />
      </Box>
      <Flex
        mt='45px'
        mb='20px'
        justifyContent='space-between'
        direction={{ base: "column", md: "row" }}
        align={{ base: "start", md: "center" }
        }>
        <Flex onClick={() => history.push(`/artist/${artist.address}`)} style={{ cursor: 'pointer' }}>
          <MiniStatistics
            startContent={
              <Avatar
                src={ipfsToHttp(artist?.logo)}
                w='35px'
                h='35px'
                me='8px'
              />

            }
            name='Artist'
            value={artist ? (artist.alias || `${artist.address.slice(0, 5)}...${artist.address.slice(-5)}`) : "-"}
          />
        </Flex>
        <Flex onClick={() => history.push(`/collection/${contract}`)} style={{ cursor: 'pointer' }}>
          <MiniStatistics

            startContent={
              <Avatar
                src={ipfsToHttp(contractImage ? contractImage : "-")}
                w='35px'
                h='35px'
                me='8px'
              />

            }
            name='Collection'
            value={contractName ? contractName : "-"}
          />
        </Flex>
      </Flex>
      <Flex
        mt='10px'
        mb='20px'
        width='50%'
        justifyContent='space-between'
        direction={{ base: "column", md: "row" }}
        align={{ base: "start", md: "center" }
        }>
        {colaboratorWallet && colaboratorWallet.length > 0 && (
          <Flex onClick={() => history.push(`/artist/${colaboratorWallet[0].address}`)} style={{ cursor: 'pointer' }}>
            <MiniStatistics

              startContent={
                <Avatar
                  src={ipfsToHttp(colaboratorWallet?.logo)}
                  w='35px'
                  h='35px'
                  me='8px'
                />

              }
              name='Collaborator'
              value={colaboratorWallet[0].alias ? colaboratorWallet[0].alias : (colaboratorWallet[0].address ? `${colaboratorWallet[0].address.slice(0, 5)}...${colaboratorWallet[0].address.slice(-5)}` : "-")}
            />
          </Flex>
        )}


      </Flex>
      <Flex
        mt='10px'
        mb='20px'
        justifyContent='space-between'
        direction={{ base: "column", md: "column" }}
        align={{ base: "start", md: "center" }
        }>


        {contractAddress && <Button
          href={objktLink}
          as="a"

          target="_blank"
          rel="noopener noreferrer"
          mt={{ base: "10px", md: "0" }}

          bg={bgColorPrimary}
          rightIcon={<FaExternalLinkAlt />}
          textColor='white'
        >
          Show on OBJKT
        </Button>}
      </Flex>

    </Card>
  );
}
