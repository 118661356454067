// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  Avatar
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import React from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdBarChart, MdOutlineCalendarToday } from "react-icons/md";
// Assets
import { RiArrowUpSFill } from "react-icons/ri";

const lineChartDataTotalSpent = [
  {
    name: "Total Volume",
    data: [],
  },
  {
    name: "Average Price",
    data: [],
  },
];

const lineChartOptionsTotalSpent = {
  chart: {
    toolbar: {
      show: false,
    },
    dropShadow: {
      enabled: true,
      top: 13,
      left: 0,
      blur: 10,
      opacity: 0.1,
      color: "#4318FF",
    },
  },
  colors: ["#4318FF", "#39B8FF"],
  markers: {
    size: 0,
    colors: "white",
    strokeColors: "#7551FF",
    strokeWidth: 3,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    shape: "circle",
    radius: 2,
    offsetX: 0,
    offsetY: 0,
    showNullDataPoints: true,
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    type: "line",
  },
  xaxis: {
    type: "numeric",
    categories: [],
    labels: {
      style: {
        colors: "#A3AED0",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
  },
  legend: {
    show: false,
  },
  grid: {
    show: false,
    column: {
      color: ["#7551FF", "#39B8FF"],
      opacity: 0.5,
    },
  },
  color: ["#7551FF", "#39B8FF"],
};


const calcPrice = (value, defaultReturn = "-") => {
  if (!value) {
    return defaultReturn;
  }
  const deal = parseInt(value) / 1000000;
  if (deal < 1) {
    return parseFloat(deal.toFixed(2));
  } else if (deal >= 1 && deal < 10) {
    return parseFloat(deal.toFixed(1));
  } else {
    return deal.toFixed(0);
  }
};


// Helper functions
function diffDays(d1, d2) {
  const oneDay = 24 * 60 * 60 * 1000;
  return Math.round(Math.abs((d1 - d2) / oneDay));
}

function formatCategoryLabel(startDate, endDate) {
  const diff = diffDays(startDate, endDate);
  if (diff > 365) {
    return startDate.getFullYear();
  } else if (diff > 30) {
    return `${startDate.toLocaleString('default', { month: 'short' })} ${startDate.getFullYear()}`;
  } else {
    return `${startDate.toLocaleString('default', { month: 'short' })} ${startDate.getDate()}, ${startDate.getFullYear()}`;
  }
}

function average(arr) {
  return arr.reduce((sum, val) => sum + val, 0) / arr.length;
}

// Categorize and aggregate data by timestamp
function createCategories(history) {
  if (history.length === 0) {
    return {
      categoryLabels: [],
      totalVolumes: [],
      averagePrices: [],
    };
  }

  const categories = {};

  history.forEach((item) => {
    const itemDate = new Date(item.timestamp);
    const dateLabel = formatCategoryLabel(itemDate, itemDate);
    if (!categories[dateLabel]) {
      categories[dateLabel] = [];
    }
    categories[dateLabel].push(item);
  });

  const categoryLabels = [];
  const totalVolumes = [];
  const averagePrices = [];

  Object.keys(categories).forEach((dateLabel) => {
    const category = categories[dateLabel];
    categoryLabels.push(dateLabel);

    const categoryPrices = category.map(item => item.price);
    totalVolumes.push(calcPrice(categoryPrices.reduce((sum, val) => sum + val, 0)));
    averagePrices.push(calcPrice(average(categoryPrices)));
  });

  return { categoryLabels, totalVolumes, averagePrices };
}


export default function TotalSpent(props) {
  const { range, lastDeal, saleHistory = [], offerHistory = [], auctionHistory = [], ...rest } = props;

  const filteredOfferHistory = offerHistory.filter(item => item.type === "offer_accept");

  const combinedHistory = [...saleHistory, ...filteredOfferHistory, ...auctionHistory];

  combinedHistory.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");

  let numCategories = Math.min(combinedHistory.length, 12);

  let { categoryLabels, totalVolumes, averagePrices } = createCategories(combinedHistory);

  let newChartData = [...lineChartDataTotalSpent];
  newChartData[0].data = totalVolumes;
  newChartData[1].data = averagePrices;

  let newChartOptions = { ...lineChartOptionsTotalSpent };
  newChartOptions.xaxis.categories = categoryLabels;

  let lastDealBeforeLast;
  let priceChangePercent;
  let priceChangeColor;
  let priceChangeSign;

  if (lastDeal) {
    let lastIndex = combinedHistory.length - 2;
    while (lastIndex >= 0 && new Date(combinedHistory[lastIndex].timestamp) >= new Date(lastDeal.timestamp)) {
      lastIndex--;
    }
    if (lastIndex >= 0) {
      lastDealBeforeLast = combinedHistory[lastIndex];
      priceChangePercent = ((lastDeal.price - lastDealBeforeLast.price) / lastDealBeforeLast.price) * 100;
      priceChangeColor = priceChangePercent >= 0 ? 'green.500' : 'red.500';
      priceChangeSign = priceChangePercent >= 0 ? '+' : '-';
      priceChangePercent = Math.abs(priceChangePercent.toFixed(2));
    }
  }

  return (
    <Card
      justifyContent='center'
      align='center'
      direction='column'
      w='100%'
      mb='20px'
      {...rest}
    >
      <Flex w='100%' flexDirection={{ base: "column", lg: "row" }}>
        <Flex flexDirection='column' me='20px' mt='10px' borderRadius='7px' padding='10px'>
          <Text
            bg={boxBg}
            fontSize='sm'
            fontWeight='500'
            color={textColorSecondary}
            borderRadius='7px'
            mb='25px'
            pt='5px'
            pb='5px'>
            Last Deal
          </Text>
          <Text
            color={textColor}
            fontSize='34px'
            textAlign='start'
            fontWeight='700'
            lineHeight='100%'
            alignSelf='center'>
            {lastDeal ? calcPrice(lastDeal.price).toString() + " Xtz" : "-"}
          </Text>

          <Flex align='center' mt='10px' justify='center'>
            <Avatar
              src={lastDeal && lastDeal.whoBuy && lastDeal.whoBuy[0] && lastDeal.whoBuy[0].logo ? lastDeal.whoBuy[0].logo : " "}

              w='30px'
              h='30px'
              me='4px'
            />
            <Text color={textColor} fontSize='md' fontWeight='700' align='center' ml='10px'>
            {
    lastDeal && lastDeal.whoBuy && lastDeal.whoBuy[0]
    ? (lastDeal.whoBuy[0].alias || `${lastDeal.whoBuy[0].address.slice(0, 5)}...${lastDeal.whoBuy[0].address.slice(-5)}`)
    : "-"
}

            </Text>
          </Flex>
        </Flex>
        <Box minH='260px' minW='75%' overflow='hidden'>
  {combinedHistory.length >= 200 ? (
    <Text
    mt='100px'
    >The chart for complete history is not available in the beta version.</Text>
  ) : combinedHistory.length <= 1 ? (
    <Text
    mt='100px'
    >Insufficient data available to display the chart.</Text>
  ) : (
    <LineChart
      chartData={newChartData}
      chartOptions={newChartOptions}
    />
  )}
</Box>

      </Flex>
    </Card>
  );
}
