// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  Icon,
  ButtonGroup,
  Spinner,
  AlertDescription,
  AlertIcon,
  Alert,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaDiscord } from "react-icons/fa";
// Custom components
import Storage from "views/admin/collector/components/Storage";
import SellStats from "views/admin/collector/components/SellStats";
import SellWays from "views/admin/collector/components/SellWays";
import Collects from "views/admin/collector/components/Collects";
import ReactGA from 'react-ga';
import { useHistory } from "react-router-dom";
import NFT from "components/card/Artist";
import Collectors from "views/admin/collector/components/Collectors";
// Assets
import React from "react";


const calcPrice = (value, defaultReturn = "-") => {
  if (!value) {
    return defaultReturn;
  }
  const deal = parseInt(value) / 1000000;
  if (deal < 1) {
    return Number(deal.toFixed(2)).toLocaleString() + " Xtz";
  } else if (deal >= 1 && deal < 10) {
    return Number(deal.toFixed(1)).toLocaleString() + " Xtz";
  } else {
    return Number(deal.toFixed(0)).toLocaleString() + " Xtz";
  }
};


export default function Overview() {
  const { walletAddress } = useParams(); // Get contractAddress and tokenId from the URL
  const [range, setRange] = useState("30d");
  const [data, setData] = useState();
  const [artistData, setArtistData] = useState();
  const [loading, setLoading] = useState(true);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const MemoizedStorage = React.memo(Storage);
  const [collections, setCollections] = useState([]);
  const [artist, setArtist] = useState([]);
  const history = useHistory();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  
    // Listen for changes
    return history.listen(location => {
      ReactGA.pageview(location.pathname + location.search);
    });
  }, [history]);

  const handleRangeChange = async (newRange) => {
    setLoading(true);
    setRange(newRange);
    try {
      let fetchURL = `https://api.pedrodig.xyz/collector/${newRange}/${walletAddress}`;
      const response = await fetch(fetchURL, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const mid = await response.json();

      // Fetch artist data
      let artistFetchURL = `https://api.pedrodig.xyz/artist/${newRange}/${walletAddress}`;
      const artistResponse = await fetch(artistFetchURL, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const artistMid = await artistResponse.json();

      if (
        mid.error === "No collector found with this address" &&
        artistMid.error === "No artist found with this address"
      ) {
        setIsErrorDialogOpen(true);
        setData(mid);
        
        console.log(range, isErrorDialogOpen);
        setLoading(false);
        return;
      }
      setData(mid);
      setCollections(mid.byCollection)
      setArtist(mid.byArtist)
        console.log("byCollectionbyCollectionbyCollectionbyCollection", mid)
      setArtistData(artistMid);
      setLoading(false);
      setIsErrorDialogOpen(false);
    } catch (error) {
      console.error("Error fetching art data:", error);
    }
  };
  const bgColorPrimary = useColorModeValue("#7551ff", "#7551ff");
  const selectedStyle = { backgroundColor: bgColorPrimary };

  useEffect(() => {
    handleRangeChange(range);
  }, [walletAddress]);

  const initialSize = window.innerWidth <= 768 ? '36px' : '24px';

  // Create a state for the icon size
  const [iconSize, setIconSize] = useState(initialSize);

  // Update icon size when window is resized
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIconSize('36px');
      } else {
        setIconSize('24px');
      }
    };

    window.addEventListener('resize', handleResize);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <Link
        href="https://discord.gg/dgaSZr5qdw"
        isExternal
        _hover={{ textDecoration: "none", color: "white" }}
      >
        <Box
          top="0"
          left="0"
          right="0"
          width="100%"
          bg={useColorModeValue("purple.600", "purple.700")}
          color="white"
          p={2}
          textAlign="center"
          zIndex="1000"
          mb="10px"
          borderRadius="5px"
        >
          <Flex alignItems="center" justifyContent="center">
          <FaDiscord style={{ margin: "8px", fontSize: iconSize }} />
          Experimental tool, but with your help, it can be useful! Join our Discord for feedback & bug reports.
          </Flex>
        </Box>
      </Link>
      {loading && (
        <Flex align="center" justify="center" minHeight="10vh">
          <Spinner size="xl" />
        </Flex>
      )}
      {isErrorDialogOpen && (
        <Alert
          status="warning"
          mb="20px"
          w="-webkit-fit-content"
          alignItems="center"
          mt="20px"
          borderRadius="10px"
          mr="20px"
          ml="20px"
        >
          <AlertIcon />
          <AlertDescription>
          This collector is not currently listed in the database.
          </AlertDescription>
        </Alert>
      )}

      <Flex direction={{ base: "column", md: "column", xl: "row" }}
      alignItems={{ base: 'center', md: 'center', xl: 'stretch' }}>
        <Flex
          direction="column"
          m="20px"
          width={{ base: "90vw", md: "90vw", xl: "50vw" }}
        >
          <Text
            marginLeft="2rem"
            marginBottom="1rem"
            fontSize="x-large"
            fontWeight="900"
          >
            Collector Details
          </Text>
          <ButtonGroup variant="outline" spacing="6" m="10px">  
      <Flex
          direction="row"
          width={{ base: "90vw", md: "45vw", xl: "45vw" }}
          sx={{
            base: { width: "-webkit-fill-available" },
            md: { width: "auto" },
            xl: { width: "auto" },
          }}
          justifyContent={{ base: "space-evenly", md: "space-evenly", xl: "start" }}
        >
     
     <Button 
          onClick={() => handleRangeChange('all')}
          style={range === 'all' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          m={{ base: "unset", md: "unset", xl: "10px" }}
        >
          From 2023-01
        </Button>
        <Button 
          onClick={() => handleRangeChange('30d')}
          style={range === '30d' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          // disabled={true}
          m={{ base: "unset", md: "unset", xl: "10px" }}
        >
          30 Days
        </Button>
        <Button 
          onClick={() => handleRangeChange('7d')}
          style={range === '7d' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          // disabled={true}
          m={{ base: "unset", md: "unset", xl: "10px" }}
        >
          7 Days
        </Button>
        <Button 
          onClick={() => handleRangeChange('24h')}
          style={range === '24h' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          m={{ base: "unset", md: "unset", xl: "10px" }}  
        >
          24 Hours
        </Button>
      
      </Flex>
      </ButtonGroup>
          <MemoizedStorage
            imageUrl={data?.collectorImageUrl}
            name={data?.collectorName}
            description={data?.collectorDescription}
            twitter={data?.collectorTwitter}
            website={data?.collectorWebsite}
            wallet={data?.collectorWallet}
          />
          {artistData &&
            artistData.error !== "No artist found with this address" && (
              <Collects data={artistData} />
            )}
          <SellWays
            totalListSold={data?.totalListBuy}
            totalOESold={data?.totalOEBuy}
            totalOfferSold={data?.totalOfferBuy}
            successAuctions={data?.totalAuctionBuy}
            way="Buy Types"
          />
          <Collectors
            collectorsList={collections ? collections : []}
            name="Spent by Collection"
          />
        </Flex>

        <Flex
          direction="column"
          m="20px"
          width={{ base: "90vw", md: "90vw", xl: "50vw" }}
        >
          <SellStats
            totalSpent={data ? data.totalSpent : 0}
            totalProfit={data ? data.totalProfit : 0}
            totalArtCollect={data ? data.totalArtCollect : 0}
            avgSpendEachArt={data ? data.avgSpendEachArt : 0}
            avgProfitPercentage={data ? data.avgProfitPercentage : 0}
            avgProfitAmount={data ? data.avgProfitAmount : 0}
            buyCount={data ? data.buyCount : 0}
            sellCount={data ? data.sellCount : 0}
            totalSell={data ? data.totalSell : 0}
          />

          <SellWays
            totalListSold={data?.totalListSell}
            totalOESold={0}
            totalOfferSold={data?.totalOfferSell}
            successAuctions={data?.totalAuctionSell}
            way="Sell Types"
          />
          <Collectors
            collectorsList={artist ? artist : []}

            name="Spent by Artist"
          />
        </Flex>
      </Flex>
      
      {/* <Flex direction="column">
        <Flex
          mt="45px"
          mb="20px"
          justifyContent="start"
          direction={{ base: "column", md: "row" }}
          align={{ base: "start", md: "center" }}
        >
          <Text color={textColor} fontSize="2xl" ms="24px" fontWeight="700">
            Top Artist by
          </Text>
          <Flex
            align="center"
            me="20px"
            ml="20px"
            ms={{ base: "24px", md: "0px" }}
            mt={{ base: "20px", md: "0px" }}
          >
            <Link
              ml="20px"
              color={textColorBrand}
              fontWeight="500"
              me={{ base: "34px", md: "44px" }}
              to="#art"
            >
              Spend
            </Link>
            <Link
              color={textColorBrand}
              fontWeight="500"
              me={{ base: "34px", md: "44px" }}
              to="#music"
            >
              Profit
            </Link>
          </Flex>
        </Flex>

        <Flex wrap="wrap" justify="start" mb={{ base: "20px", xl: "0px" }}>
          <Box mr="20px" mb="20px">
            <NFT
              name="Artist Name"
              image={Nft1}
              totalProfit="110 Xtz"
              totalSpend="580 Xtz"
            />
          </Box>
          <Box mr="20px" mb="20px">
            <NFT
              name="Artist Name"
              image={Nft2}
              totalProfit="110 Xtz"
              totalSpend="580 Xtz"
            />
          </Box>
          <Box mb="20px">
            <NFT
              name="Artist Name"
              image={Nft3}
              totalProfit="110 Xtz"
              totalSpend="580 Xtz"
            />
          </Box>
        </Flex>

        <Flex
          align="center"
          me="20px"
          ml="20px"
          ms={{ base: "24px", md: "0px" }}
          mt={{ base: "20px", md: "0px" }}
        >
          <Text
            mt="45px"
            mb="36px"
            color={textColor}
            fontSize="2xl"
            ms="24px"
            fontWeight="700"
          >
            Top Collection by
          </Text>
          <Link
            mt="20px"
            ml="20px"
            color={textColorBrand}
            fontWeight="500"
            me={{ base: "34px", md: "44px" }}
            to="#art"
          >
            Spend
          </Link>
          <Link
            mt="20px"
            color={textColorBrand}
            fontWeight="500"
            me={{ base: "34px", md: "44px" }}
            to="#music"
          >
            Profit
          </Link>
        </Flex>
        <Flex wrap="wrap" justify="start">
          <Box mr="20px" mb="20px">
            <NFT
              name="Collection Name"
              image={Nft4}
              totalProfit="110 Xtz"
              totalSpend="580 Xtz"
            />
          </Box>
          <Box mr="20px" mb="20px">
            <NFT
              name="Collection Name"
              image={Nft5}
              totalProfit="110 Xtz"
              totalSpend="580 Xtz"
            />
          </Box>
          <Box mb="20px">
            <NFT
              name="Collection Name"
              image={Nft6}
              totalProfit="110 Xtz"
              totalSpend="580 Xtz"
            />
          </Box>
        </Flex>
        <Flex
          align="center"
          me="20px"
          ml="20px"
          ms={{ base: "24px", md: "0px" }}
          mt={{ base: "20px", md: "0px" }}
        >
          <Text
            mt="45px"
            mb="36px"
            color={textColor}
            fontSize="2xl"
            ms="24px"
            fontWeight="700"
          >
            Top Art by
          </Text>
          <Link
            mt="20px"
            ml="20px"
            color={textColorBrand}
            fontWeight="500"
            me={{ base: "34px", md: "44px" }}
            to="#art"
          >
            Spend
          </Link>
          <Link
            mt="20px"
            color={textColorBrand}
            fontWeight="500"
            me={{ base: "34px", md: "44px" }}
            to="#music"
          >
            Profit
          </Link>
        </Flex>
        <Flex wrap="wrap" justify="start">
          <Box mr="20px" mb="20px">
            <Art
              name="Art Name"
              image={Nft4}
              totalProfit="110 Xtz"
              totalSpend="580 Xtz"
            />
          </Box>
          <Box mr="20px" mb="20px">
            <Art
              name="Art Name"
              image={Nft5}
              totalProfit="110 Xtz"
              totalSpend="580 Xtz"
            />
          </Box>
          <Box mb="20px">
            <Art
              name="Art Name"
              image={Nft6}
              totalProfit="110 Xtz"
              totalSpend="580 Xtz"
            />
          </Box>
        </Flex>
      </Flex> */}
    </Box>
  );
}
