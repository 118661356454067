// Chakra imports
import {
  Box,
  Flex,
  Text,
  Link,
  useColorModeValue,
  Spinner,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { FaDiscord } from "react-icons/fa";
// Custom components
import SellStats from "views/admin/main/components/SellStats";
import SellWays from "views/admin/main/components/SellWays";
import Collectors from "views/admin/main/components/Collectors";

import Art from "components/card/Mint";
import WeeklyRevenue from "views/admin/main/components/WeeklyRevenue";
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga';


export default function Overview() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const [range, setRange] = useState("24h");
  const bgColorPrimary = useColorModeValue("#7551ff", "#7551ff");
  const [sellStatsData, setSellStatsData] = useState({});
  const [sellWaysData, setSellWaysData] = useState({});
  const [transnData, setTransnData] = useState({ sevenDays: [] });
  const [artistDetailsData, setArtistDetailsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleRangeChange = async (newRange) => {
    setRange(newRange);
    setLoading(true);
    let page = 1;
    let artistData = [];
    while (true) {
      try {
        let fetchURL = `https://api.pedrodig.xyz/artists/${newRange}?limit=100&page=${page}`;
        const response1 = await fetch(fetchURL, {
          method: "GET", // Explicitly define the HTTP method

          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        });
        const data1 = await response1.json();
        artistData = artistData.concat(data1);
        if (data1.length < 100) {
          break;
        }
        page += 1;
      } catch (error) {
        console.error("Error fetching artist data:", error);
        break;
      }
    }
    console.log("artistDataartistDataartistData", artistData);
    const sellStatsData = artistData.reduce(
      (summary, artist) => {
        // Calculate totalBuyer
        const collectorWallets = artist.collectorsList
          ? artist.collectorsList.map((collector) =>
              collector.wallet.toLowerCase()
            )
          : [];

        const uniqueCollectorWallets = [...new Set(collectorWallets)];
        summary.totalBuyer += uniqueCollectorWallets.length;

        summary.primaryAvgDeal += artist.primaryAvgDeal || 0;
        summary.secondaryAvgDeal += artist.secondaryAvgDeal || 0;
        summary.totalPrimaryCount += artist.totalPrimaryCount || 0;
        summary.totalSecondaryCount += artist.totalSecondaryCount || 0;

        // Calculate other properties
        summary.avgDeal += artist.avgDeal || 0;
        summary.totalPrimarySell += artist.totalPrimarySell || 0;
        summary.totalSecondarySell += artist.totalSecondarySell || 0;
        summary.totalArts += artist.totalArts || 0;
        summary.totalEditions += artist.totalEditions || 0;

        return summary;
      },
      {
        avgDeal: 0,
        totalPrimarySell: 0,
        totalSecondarySell: 0,
        totalArts: 0,
        totalEditions: 0,
        totalBuyer: 0,
        primaryAvgDeal: 0,
        secondaryAvgDeal: 0,
        totalPrimaryCount: 0,
        totalSecondaryCount: 0,
      }
    );

    // Calculate averages
    sellStatsData.avgDeal /= artistData.length;
    sellStatsData.primaryAvgDeal /= artistData.length;
    sellStatsData.secondaryAvgDeal /= artistData.length;

    sellStatsData.totalSeller = artistData.length;

    const sellWaysData = artistData.reduce(
      (summary, artist) => ({
        totalAuctionSold: summary.totalAuctionSold + artist.totalAuctionSold,
        totalOfferSold: summary.totalOfferSold + artist.totalOfferSold,
        totalListSold: summary.totalListSold + artist.totalListSold,
        totalOESold: summary.totalOESold + artist.totalOESold,
      }),
      {
        totalAuctionSold: 0,
        totalOfferSold: 0,
        totalListSold: 0,
        totalOESold: 0,
      }
    );

    const pieChartData = [
      parseInt(sellWaysData.totalListSold),
      parseInt(sellWaysData.totalAuctionSold),
      parseInt(sellWaysData.totalOfferSold),
      parseInt(sellWaysData.totalOESold),
    ];

    setSellStatsData(sellStatsData);
    setSellWaysData(pieChartData);
    let transactionData;
    try {
      let fetchURL2 = `https://api.pedrodig.xyz/transactions`;
      const response2 = await fetch(fetchURL2, {
        method: "GET", // Explicitly define the HTTP method

        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      });
      const data2 = await response2.json();
      transactionData = data2;
    } catch (error) {
      console.error("Error fetching transactions data:", error);
    }
    let mintsObject = {
      name: "mints",
      data: [],
    };

    let transactionsObject = {
      name: "transactions",
      data: [],
    };

    if (
      transactionData &&
      transactionData.sevenDays &&
      transactionData.sevenDays.length > 0
    ) {
      let reversedSevenDays = transactionData.sevenDays.slice().reverse();

      reversedSevenDays.forEach((item) => {
        mintsObject.data.push(item.mints);
        transactionsObject.data.push(item.transactions);
      });

      let finalArray = [mintsObject, transactionsObject];
      setTransnData(finalArray);
    }
    try {
      const mintsData = await getMints();
      const sellerWallets = mintsData.map((item) => ({
        artistWallet: item.sellerWallet,
        contractAddress: item.whatBuy.contractAddress,
        tokenId: item.whatBuy.tokenId,
      }));
      const artistDetailsData = await getArtistDetails(sellerWallets);

      setArtistDetailsData(artistDetailsData);
      console.log("mintsDatamintsDatamintsDatamintsData", artistDetailsData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const getArtistDetails = async (walletAndContractPairs) => {
    const response = await fetch(`https://api.pedrodig.xyz/lastmint/batch`, {
      method: "POST",

      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(walletAndContractPairs),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  };

  const getMints = async () => {
    let fetchURL = "https://api.pedrodig.xyz/transactions/mints";
    const response = await fetch(fetchURL, {
      method: "GET", // Explicitly define the HTTP method

      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  };

  useEffect(() => {
    handleRangeChange(range);
  }, []);

  const selectedStyle = { backgroundColor: bgColorPrimary };
  const history = useHistory();
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  
    // Listen for changes
    return history.listen(location => {
      ReactGA.pageview(location.pathname + location.search);
    });
  }, [history]);

  const [displayCount, setDisplayCount] = useState(8); // Set initial display count to 10

  const handleShowMore = () => {
    setDisplayCount(displayCount + 8);
  }

  const initialSize = window.innerWidth <= 768 ? '36px' : '24px';

  // Create a state for the icon size
  const [iconSize, setIconSize] = useState(initialSize);

  // Update icon size when window is resized
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIconSize('36px');
      } else {
        setIconSize('24px');
      }
    };

    window.addEventListener('resize', handleResize);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <Link
        href="https://discord.gg/dgaSZr5qdw"
        isExternal
        _hover={{ textDecoration: "none", color: "white" }}
      >
        <Box
          top="0"
          left="0"
          right="0"
          width="100%"
          bg={useColorModeValue("purple.600", "purple.700")}
          color="white"
          p={2}
          textAlign="center"
          zIndex="1000"
          mb={{ base: "20px", md: "10px", xl: "10px" }}
          borderRadius="5px"
        >
          <Flex alignItems="center" justifyContent="center">
            <FaDiscord style={{ margin: "8px", fontSize: iconSize }} />
            Experimental tool, but with your help, it can be useful! Join our Discord for feedback & bug reports.
          </Flex>
        </Box>
      </Link>
      <Flex
        direction={{ base: "column", md: "column", xl: "row" }}
        justifyContent="space-between"
        alignItems="center"
        width={{ base: "90vw", md: "95vw", xl: "95vw" }}
      >
        <Flex px='25px' justify='space-between' mb='20px' align='center' direction={{ base: "column", md: "column", xl: "column" }}
        >
<Text
            marginTop="1rem"
            marginBottom="1rem"

            alignSelf={{ base: "auto", md: "baseline", xl: "baseline" }}
            fontSize={{ base: "medium", md: "large", xl: "large" }}

            fontWeight="900"
          >
            Transaction Overview on Objkt:
          </Text>

        <ButtonGroup >
        <Flex
          direction="row"
          width={{ base: "90vw", md: "45vw", xl: "45vw" }}
          sx={{
            base: { width: "-webkit-fill-available" },
            md: { width: "auto" },
            xl: { width: "auto" },
          }}
        >
          
            <Button
              onClick={() => handleRangeChange("30d")}
              style={range === "30d" ? selectedStyle : {}} // Apply selected style if this is the selected range
              mr="20px"
              disabled={true}
            >
              30 Days
            </Button>
            <Button
              onClick={() => handleRangeChange("7d")}
              style={range === "7d" ? selectedStyle : {}} // Apply selected style if this is the selected range
              mr="20px"
              // disabled={true}
            >
              7 Days
            </Button>
            <Button
              onClick={() => handleRangeChange("24h")}
              style={range === "24h" ? selectedStyle : {}} // Apply selected style if this is the selected range
              mr="20px"
            >
              24 Hours
            </Button>
            {loading && (
          <Flex align="center" justify="center"   Height="3rem">
            <Spinner size="xl" />
          </Flex>
        )}
        </Flex>
        </ButtonGroup>
        </Flex>
        <Alert
          status="warning"
          mb="20px"
          w="-webkit-fit-content"
          alignItems="center"
          mt="20px"
          borderRadius="10px"
        >
          <AlertIcon />
          <AlertDescription>
          Since 30 Oct 16:00 UTC, Pedrodig has been actively monitoring objkt.com's live data and is still populating 7-day and 30-day data. Thank you for your patience.
          </AlertDescription>
        </Alert>
      </Flex>
      <Flex direction={{ base: "column", md: "column", xl: "column" }}>
      <Flex direction={{ base: "column", md: "column", xl: "row" }}
      alignItems={{ base: 'center', md: 'center', xl: 'stretch' }}>
        <Flex
          direction="column"
          m="20px"
          width={{ base: "90vw", md: "90vw", xl: "40vw" }}
        >
          <SellStats data={sellStatsData} />
          
        </Flex>
        <Flex
          direction="column"
          m="20px"
          width={{ base: "90vw", md: "90vw", xl: "30vw" }}
        >
          <SellWays data={sellWaysData} />
        </Flex>
        <Flex
          direction="column"
          m="20px"
          width={{ base: "90vw", md: "90vw", xl: "30vw" }}
        >
          <WeeklyRevenue data={transnData} />
        </Flex>
        
          </Flex>
          <Collectors
          />
      </Flex>
      {/* <Flex direction="column" align="center">
    <Flex
      mt="45px"
      mb="20px"
      justifyContent="start"
      direction={{ base: "column", md: "row" }}
      align={{ base: "start", md: "center" }}
      width="100%"
    >
      <Text color={textColor} fontSize="2xl" ms="24px" fontWeight="700">
        Latest Mints
      </Text>
    </Flex>

    {loading ? (
      <Box mt="20px" mb="20px">
        <Spinner size="xl" />
      </Box>
    ) : (
      <>
        <Flex 
          direction={{ base: "column", md: "row" }} 
          wrap="wrap" 
          justify="center"
        >
          {artistDetailsData.slice(0, displayCount).map((artist) => (
            <Box
              key={artist.address}
              mr="20px"
              mb="20px"
              onClick={() =>
                history.push(
                  `/art/${artist.data.contractAddress}/${artist.data.tokenId}`
                )
              }
              style={{ cursor: "pointer" }}
            >
              <Art
                name={artist.data.artName}
                artImage={
                  artist.data.artImage ? artist.data.artImage[2].url : "non"
                }
                artistName={artist.data.artistName}
                avgProfit={artist.data.avgProfitPercentage}
                avgList={
                  artist.data.primaryListToSellTime
                    ? artist.data.primaryListToSellTime.Avg
                    : null
                }
                collectors={artist.data.totalCollectors}
                image={artist.data.artistImage}
                totalArts={artist.data.totalArts}
              />
            </Box>
          ))}
        </Flex>

        {displayCount < artistDetailsData.length && (
          <Button mt="20px" onClick={handleShowMore}>Show More</Button>
        )}
      </>
    )}
</Flex> */}

    </Box>
  );
}

// <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//       {/* Main Fields */}
//       <Grid
//         templateColumns={{
//           base: "1fr",
//           lg: "1.34fr 1fr 1.62fr",
//         }}
//         templateRows={{
//           base: "repeat(3, 1fr)",
//           lg: "1fr",
//         }}
//         gap={{ base: "20px", xl: "20px" }}>
//         <Banner
//           gridArea='1 / 1 / 2 / 2'
//           banner={banner}
//           avatar={avatar}
//           name='Adela Parkson'
//           job='Product Designer'
//           posts='17'
//           followers='9.7k'
//           following='274'
//         />
//         <Storage
//           gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
//           used={25.6}
//           total={50}
//         />
//         <Upload
//           gridArea={{
//             base: "3 / 1 / 4 / 2",
//             lg: "1 / 3 / 2 / 4",
//           }}
//           minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
//           pe='20px'
//           pb={{ base: "100px", lg: "20px" }}
//         />
//       </Grid>
//       <Grid
//         mb='20px'
//         templateColumns={{
//           base: "1fr",
//           lg: "repeat(2, 1fr)",
//           "2xl": "1.34fr 1.62fr 1fr",
//         }}
//         templateRows={{
//           base: "1fr",
//           lg: "repeat(2, 1fr)",
//           "2xl": "1fr",
//         }}
//         gap={{ base: "20px", xl: "20px" }}>
//         <Projects
//           gridArea='1 / 2 / 2 / 2'
//           banner={banner}
//           avatar={avatar}
//           name='Adela Parkson'
//           job='Product Designer'
//           posts='17'
//           followers='9.7k'
//           following='274'
//         />
//         <General
//           gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
//           minH='365px'
//           pe='20px'
//         />
//         <Notifications
//           used={25.6}
//           total={50}
//           gridArea={{
//             base: "3 / 1 / 4 / 2",
//             lg: "2 / 1 / 3 / 3",
//             "2xl": "1 / 3 / 2 / 4",
//           }}
//         />
//       </Grid>
//     </Box>
