import {
  Box,
  Flex,
  Button,
  Text,
  ButtonGroup,
  useColorModeValue,
  Spinner,
  Alert,
  AlertDescription,
  AlertIcon,
  Link,
} from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom"; // Import useParams
import { FaDiscord } from "react-icons/fa";
import Banner from "views/admin/art/components/Banner";
import General from "views/admin/art/components/General";
import SellStats from "views/admin/art/components/SellStats";
import ReactGA from 'react-ga';
import ListToSell from "views/admin/art/components/ListToSell";
import Collectors from "views/admin/art/components/Collectors";
import Chart from "views/admin/art/components/Chart";
import Auction from "views/admin/art/components/Auction";
import SellWays from "views/admin/art/components/SellWays";
import React from "react";
import { useHistory } from "react-router-dom";

export default function Overview() {
  const { contractAddress, tokenId } = useParams(); // Get contractAddress and tokenId from the URL
  const [range, setRange] = useState("all");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const cancelRef = useRef();
  const MemoizedBanner = React.memo(Banner);
  const MemoizedGeneral = React.memo(General);
  const history = useHistory();
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  
    // Listen for changes
    return history.listen(location => {
      ReactGA.pageview(location.pathname + location.search);
    });
  }, [history]);

  const handleRangeChange = async (newRange) => {
    setLoading(true);
    setRange(newRange);
    try {
      let fetchURL = `https://api.pedrodig.xyz/art/${newRange}/${contractAddress}/${tokenId}`;
      const response = await fetch(fetchURL, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const mid = await response.json();
      console.log(range, mid);
      if (mid.error === "No item found") {
        setIsErrorDialogOpen(true);
        setData(mid);
        console.log(range, isErrorDialogOpen);
        setLoading(false);
        return;
      }
      setData(mid);
      setLoading(false);
      setIsErrorDialogOpen(false);
    } catch (error) {
      console.error("Error fetching art data:", error);
    }
  };

  const bgColorPrimary = useColorModeValue("#7551ff", "#7551ff");
  const selectedStyle = { backgroundColor: bgColorPrimary };

  useEffect(() => {
    handleRangeChange(range);
  }, [contractAddress, tokenId]);

  const initialSize = window.innerWidth <= 768 ? '36px' : '24px';

  // Create a state for the icon size
  const [iconSize, setIconSize] = useState(initialSize);

  // Update icon size when window is resized
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIconSize('36px');
      } else {
        setIconSize('24px');
      }
    };

    window.addEventListener('resize', handleResize);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
        <Link
          href="https://discord.gg/dgaSZr5qdw"
          isExternal
          _hover={{ textDecoration: "none", color: "white" }}
        >
          <Box
            top="0"
            left="0"
            right="0"
            width="100%"
            bg={useColorModeValue("purple.600", "purple.700")}
            color="white"
            p={2}
            textAlign="center"
            zIndex="1000"
            mb="10px"
            borderRadius="5px"
          >
            <Flex alignItems="center" justifyContent="center">
            <FaDiscord style={{ margin: "8px", fontSize: iconSize }} />
            Experimental tool, but with your help, it can be useful! Join our Discord for feedback & bug reports.
            </Flex>
          </Box>
        </Link>
        {loading && (
          <Flex align="center" justify="center" minHeight="10vh">
            <Spinner size="xl" />
          </Flex>
        )}
        {isErrorDialogOpen && (
          <Alert
            status="warning"
            mb="20px"
            w="-webkit-fit-content"
            alignItems="center"
            mt="20px"
            borderRadius="10px"
            mr="20px"
            ml="20px"
          >
            <AlertIcon />
            <AlertDescription>
              This artwork is not currently listed in the database.
            </AlertDescription>
          </Alert>
        )}
        {data && data.artist && data.artist.address.startsWith("kt") && (
          <Alert
            status="warning"
            mb="20px"
            w="-webkit-fit-content"
            alignItems="center"
            mt="20px"
            borderRadius="10px"
            mr="20px"
            ml="20px"
          >
            <AlertIcon />
            <AlertDescription>
            This artwork was not minted on objkt, so the data is not complete.
            </AlertDescription>
          </Alert>
        )}
        {data && data.contractAddress && data.contractAddress === "KT1LjmAdYQCLBjwv4S2oFkEzyHVkomAf5MrW" && (
          <Alert
            status="warning"
            mb="20px"
            w="-webkit-fit-content"
            alignItems="center"
            mt="20px"
            borderRadius="10px"
            mr="20px"
            ml="20px"
          >
            <AlertIcon />
            <AlertDescription>
            This artwork was not minted on objkt, so the data is not complete.
            </AlertDescription>
          </Alert>
        )}
        <Flex direction={{ base: "column", md: "column", xl: "row" }}
        alignItems={{ base: 'center', md: 'center', xl: 'stretch' }}>
          <Flex
            direction="column"
            m="20px"
            width={{ base: "90vw", md: "90vw", xl: "30vw" }}
            
          >
            <Text
              marginLeft="2rem"
              marginBottom="1rem"
              fontSize="x-large"
              fontWeight="900"
            >
              Art Details
            </Text>

            <ButtonGroup variant="outline" spacing="6" m="10px">  
      <Flex
          direction="row"
          width={{ base: "90vw", md: "45vw", xl: "45vw" }}
          sx={{
            base: { width: "-webkit-fill-available" },
            md: { width: "auto" },
            xl: { width: "auto" },
          }}
          justifyContent={{ base: "space-evenly", md: "space-evenly", xl: "start" }}
        >
     
     <Button 
          onClick={() => handleRangeChange('all')}
          style={range === 'all' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          m={{ base: "unset", md: "unset", xl: "10px" }}
        >
          All
        </Button>
        <Button 
          onClick={() => handleRangeChange('30d')}
          style={range === '30d' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          // disabled={true}
          m={{ base: "unset", md: "unset", xl: "10px" }}
        >
          30 Days
        </Button>
        <Button 
          onClick={() => handleRangeChange('7d')}
          style={range === '7d' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          // disabled={true}
          m={{ base: "unset", md: "unset", xl: "10px" }}
        >
          7 Days
        </Button>
        <Button 
          onClick={() => handleRangeChange('24h')}
          style={range === '24h' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          m={{ base: "unset", md: "unset", xl: "10px" }}  
        >
          24 Hours
        </Button>
      
      </Flex>
      </ButtonGroup>

            <MemoizedBanner
              artImage={data?.artImage}
              artist={data?.artist}
              colaboratorWallet={data?.colaboratorWallet}
              contract={data?.contractAddress}
              contractImage={data?.contractImage}
              contractName={data?.contractName}
              contractAddress={data?.contractAddress}
              tokenId={data?.tokenId}
            />
            <MemoizedGeneral
              artDescription={data?.artDescription}
              artName={data?.artName}
              royalty={
                data
                  ? data.royalty / Math.pow(10, data.royaltyDecimal - 2)
                  : "-"
              }
              tags={data?.tags}
              burns={data?.burns}
              mintTime={data?.mintTime}
              lastUpdated={data?.lastUpdated}
              isOpenEdition={data?.isOpenEdition}
              editions={data?.editions}
            />
          </Flex>
          <Flex
            direction="column"
            m="20px"
            width={{ base: "90vw", md: "90vw", xl: "35vw" }}
          >
            <SellStats
              totalVol={
                data ? data.totalPrimarySell + data.totalSecondarySell : 0
              }
              totalPrimarySell={data ? data.totalPrimarySell : 0}
              totalSecondarySell={data ? data.totalSecondarySell : 0}
              totalProfitAmount={data ? data.totalProfitAmount : 0}
              avgProfitAmount={data ? data.avgProfitAmount : 0}
              avgProfitPercentage={data ? data.avgProfitPercentage : 0}
              secondaryCount={data ? data.secondaryCount : 0}
              primaryCount={data ? data.primaryCount : 0}
              avgDeal={data ? data.avgDeal : 0}
              primaryAvgDeal={data ? data.primaryAvgDeal : 0}
              secondaryAvgDeal={data ? data.secondaryAvgDeal : 0}
            />
            <Chart
              lastDeal={data?.lastDeal}
              saleHistory={data?.saleHistory}
              offerHistory={data?.offerHistory}
              auctionHistory={data?.auctionHistory}
              range={range}
            />
            <Collectors
              collectorsList={data?.collectorsList}
              totalHolders={data?.totalHolders}
              totalCollectors={data?.totalCollectors}
            />
          </Flex>
          <Flex
            direction="column"
            m="20px"
            width={{ base: "90vw", md: "90vw", xl: "25vw" }}
          >
            <ListToSell
              primaryListToSellTime={data?.primaryListToSellTime}
              secondaryListToSellTime={data?.secondaryListToSellTime}
            />
            <SellWays
              totalListSold={data?.totalListSold}
              totalOESold={data?.totalOESold}
              totalOfferSold={data?.totalOfferSold}
              successAuctions={data?.successAuctions}
            />
            <Auction
              successAuctions={data?.successAuctions}
              failedAuctions={data?.failedAuctions}
              avgReserveAuction={data?.avgReserveAuction}
              avgSettleAuction={data?.avgSettleAuction}
            />
          </Flex>
        </Flex>
      </Box>
    </>
  );
}

{
  /* <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1.2fr 1.6fr 1fr",
        }}
        templateRows={{
          base: "repeat(8, 1fr)",  // Adjust the repeat count based on your components
          lg: "8fr",  // Adjust the fr count based on your components
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <Banner
          gridArea={{ base: "1 / 1 / 2 / 2", lg: "1 / 1 / 3 / 2" }}
          banner={banner}
        />
        <SellStats
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          minH='365px'
          pe='20px'
        />
        <ListToSell
          gridArea={{ base: "3 / 1 / 4 / 2", lg: "1 / 3 / 2 / 4" }}
          minH='365px'
          pe='20px'
        />
        <General
          gridArea={{ base: "4 / 1 / 5 / 2", lg: "2 / 1 / 3 / 2" }}
          minH='365px'
          pe='20px'
          description='Teeesst'
        />
        
        
        
        <Chart
          gridArea={{ base: "6 / 1 / 7 / 2", lg: "2 / 2 / 3 / 3" }}
          mt={{ lg: "-110px" }}
        />
        
        <Collectors
          gridArea={{ base: "7 / 1 / 8 / 2", lg: "3 / 2 / 4 / 3" }}
          tableData={tableDataTopCreators}
          columnsData={tableColumnsTopCreators}
        />
        <SellWays
          gridArea={{ base: "8 / 1 / 9 / 2", lg: "2 / 3 / 3 / 4" }}
          minH='365px'
          pe='20px'
          mt={{ lg: "-110px" }}
        />
        <Auction
          gridArea={{ base: "9 / 1 / 10 / 2", lg: "3 / 3 / 4 / 4 " }}
          minH='365px'
          pe='20px'
        />
      </Grid>
    </Box> */
}

// <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//       {/* Main Fields */}
//       <Grid
//         templateColumns={{
//           base: "1fr",
//           lg: "1.34fr 1fr 1.62fr",
//         }}
//         templateRows={{
//           base: "repeat(3, 1fr)",
//           lg: "1fr",
//         }}
//         gap={{ base: "20px", xl: "20px" }}>
//         <Banner
//           gridArea='1 / 1 / 2 / 2'
//           banner={banner}
//           avatar={avatar}
//           name='Adela Parkson'
//           job='Product Designer'
//           posts='17'
//           followers='9.7k'
//           following='274'
//         />
//         <Storage
//           gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
//           used={25.6}
//           total={50}
//         />
//         <Upload
//           gridArea={{
//             base: "3 / 1 / 4 / 2",
//             lg: "1 / 3 / 2 / 4",
//           }}
//           minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
//           pe='20px'
//           pb={{ base: "100px", lg: "20px" }}
//         />
//       </Grid>
//       <Grid
//         mb='20px'
//         templateColumns={{
//           base: "1fr",
//           lg: "repeat(2, 1fr)",
//           "2xl": "1.34fr 1.62fr 1fr",
//         }}
//         templateRows={{
//           base: "1fr",
//           lg: "repeat(2, 1fr)",
//           "2xl": "1fr",
//         }}
//         gap={{ base: "20px", xl: "20px" }}>
//         <Projects
//           gridArea='1 / 2 / 2 / 2'
//           banner={banner}
//           avatar={avatar}
//           name='Adela Parkson'
//           job='Product Designer'
//           posts='17'
//           followers='9.7k'
//           following='274'
//         />
//         <General
//           gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
//           minH='365px'
//           pe='20px'
//         />
//         <Notifications
//           used={25.6}
//           total={50}
//           gridArea={{
//             base: "3 / 1 / 4 / 2",
//             lg: "2 / 1 / 3 / 3",
//             "2xl": "1 / 3 / 2 / 4",
//           }}
//         />
//       </Grid>
//     </Box>
