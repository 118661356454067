/*eslint-disable*/
import React, { useState } from "react";
import {
  Icon,
  Flex,
  Link,
  List,
  ListItem,
  Text,
  Button,
  useColorMode,
  useColorModeValue,
  Collapse,
  IconButton,
  Box,
  Image
} from "@chakra-ui/react";
import { FaTwitter, FaLinkedin, FaGlobe, FaChevronUp } from 'react-icons/fa';
import Tlt from "assets/img/nfts/tlt.gif";

export default function Footer() {
  const textColor = useColorModeValue("gray.400", "white");
  const { toggleColorMode } = useColorMode();

  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);

  return (
    <Flex
      zIndex='3'
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent='space-between'
      px={{ base: "30px", md: "50px" }}
      pb='30px'>
      <Text
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}>
          <Link href="https://www.pedrolog.com/" isExternal mx='5px'>
    <Icon as={FaGlobe} w={4} h={4} />
  </Link>
<Link href="https://twitter.com/Pedroooart" isExternal mx='5px'>
    <Icon as={FaTwitter} w={4} h={4} />
  </Link>
  <Link href="https://www.linkedin.com/in/pedro-martinezz/" isExternal mx='5px'>
    <Icon as={FaLinkedin} w={4} h={4} />
  </Link>
        <Text as='span' fontWeight='500' ms='4px'>
        Pedro here! Boost my passion by collecting 
        <Link
            mx='3px'
            color={textColor}
            href='https://objkt.com/profile/tz1QNvPFtrrgEUmvktEwofvt6XHH2WT7EXKi/created'
            target='_blank'
            fontWeight='700'>
          <Image
          src={Tlt}
          display='inline'
          mr='5px'
          ml='5px'
          mb= '-8px'
          w='25px'
          h='25px'
          />
          
            TezosLivingThings.
          </Link>
          
        </Text>
      </Text>
      
      <List display='flex'>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Box position="relative" zIndex="tooltip">
          <Button 
  leftIcon={<Icon as={FaChevronUp} />} 
  onClick={handleToggle}
  color={textColor}
  variant="link"
  _hover={{
    textDecoration: 'none'
  }}
>
  Disclaimer
</Button>

            <Collapse in={show} animateOpacity>
            <Box
  position="absolute"
  bottom="100%"
  left= {{ base: "-30vw", md: "-20vw", xl: "-20vw" }}
  bg="white"
  p="4"
  rounded="md"
  shadow="md"
  color="black"
  mb='10px'
  width={{ base: "80vw", md: "60vw", xl: "30vw" }}
>
                Pedrodig, crafted by me, Pedro, is a free tool offering analytics of artists, collections, arts, and collectors from public blockchain records. Despite striving for accuracy, some data may contain errors. Note, Pedrodig only supports the new marketplace contract of objkt.com and arts minted on Hicetnunc. This free service is not a guide for trading decisions. Trade smartly!
              </Box>
            </Collapse>
          </Box>
        </ListItem>
      </List>
    </Flex>
  );
}
