/* eslint-disable */
import {
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Checkbox,
  Select,
  Button,
  Input,
  css,
  ButtonGroup, Alert, AlertIcon, AlertDescription, Avatar, useTheme
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { AndroidLogo, AppleLogo, WindowsLogo } from "components/icons/Icons";
import Menu from "components/menu/MainMenu";
import React, { useMemo, useEffect } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';


const calculateRelativeTime = (mintTime) => {
  const mintDate = new Date(mintTime);
  const now = new Date();
  const diffTime = Math.abs(now - mintDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // diff in days
  
  if (diffDays < 30) {
      return `${diffDays} day${diffDays > 1 ? 's' : ''}`;
  } else if (diffDays < 365) {
      const diffMonths = Math.floor(diffDays / 30);
      const remainingDays = diffDays % 30;
      return `${diffMonths}M${remainingDays > 0 ? ' and '+remainingDays+'d' : ''}`;
  } else {
      const diffYears = Math.floor(diffDays / 365);
      const remainingMonths = Math.floor((diffDays % 365) / 30);
      return `${diffYears}Y${remainingMonths > 0 ? ' and '+remainingMonths+'d' : ''}`;
  }
}

const truncate = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}


export default function DevelopmentTable(props) {
  const { columnsData,
    tableData,
    setOneOfOneEdition,
    setSort,
    setSecondaryCount,
    oneOfOneEdition,
    sort,
    secondaryCount,
    currentPage,
    handlePageChange,
    handleNextPage,
    handlePreviousPage,
    itemsPerPage,
    handleRangeChange,
    range } = props;

  const columns = useMemo(
    () =>
      columnsData
        .filter(
          (column) =>
            column.accessor !== "artImage" &&
            column.accessor !== "artistImage" &&
            column.accessor !== "artistWallet" &&
            column.accessor !== "contractAddress"
        )
        .map((column) => {
          if (column.accessor === "age" || column.accessor === "lastDeal") {
            return { ...column, disableSortBy: true };
          }
          return column;
        }),
    [columnsData]
  );
  
  const data = useMemo(() => tableData.filter(row => row.totalArts !== 0), [tableData]);
  const bgColorPrimary = useColorModeValue("#7551ff", "#7551ff");
  const selectedStyle = { backgroundColor: bgColorPrimary };
  const history = useHistory();
  const location = useLocation();
  const paramsPresent = location.search.length > 0;
  const [sortedColumn, setSortedColumn] = React.useState('');
const [sortDirection, setSortDirection] = React.useState('');

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    state: { sortBy },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;

  
  React.useEffect(() => {
    const [soColumn, soDirection] = sort.split('-');
    setSortedColumn(soColumn);
    setSortDirection(soDirection);
  
  }, [sort]);  // Listen to changes in the sort prop instead
  
  React.useEffect(() => {
    console.log(sortedColumn, sortDirection);
  }, [sortedColumn, sortDirection]);

  const handleSort = (columnId, direction) => {
    setSort(`${columnId}-${direction}`);
    history.push(`?sort=${columnId}-${direction}`);
  };
  
  const handleURLParameters = () => {
    const currentParams = new URLSearchParams(location.search);
    const sortParam = currentParams.get("sort");
    const oneOfOneParam = currentParams.get("oneOfOne");
    const hasSecondaryParam = currentParams.get("hasSecondary");

    // Update component state
    if (sortParam) setSort(sortParam);
    if (oneOfOneParam) setOneOfOneEdition(oneOfOneParam === "true");
    if (hasSecondaryParam) setSecondaryCount(hasSecondaryParam === "true");

    // Update URL based on current state
    const newParams = {};
    if (sort) newParams.sort = sort;

    if (oneOfOneEdition !== false) {
      if (oneOfOneParam) setOneOfOneEdition(oneOfOneParam === "true");
    }
    if (secondaryCount !== false) {
      if (hasSecondaryParam) setSecondaryCount(hasSecondaryParam === "true");
    }
    history.replace({
      pathname: location.pathname,
      search: new URLSearchParams(newParams).toString(),
    });
  };


  React.useEffect(() => {
    
    handleURLParameters();
    // Trigger the effect on these dependencies
  }, [location.search, sort, oneOfOneEdition, secondaryCount]);
  
  
  initialState.pageSize = 20;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const ipfsToHttp = (ipfsUrl) => {
    if(ipfsUrl) {
      return ipfsUrl.replace('ipfs://', 'https://ipfs.io/ipfs/');
    }
    return null;
  }

  const theme = useTheme();
  const ScrollbarCSS = css({
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: theme.colors.gray[200], // match to your theme colors
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.gray[500], // match to your theme colors
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: theme.colors.gray[700], // match to your theme colors
    },
  });

  return (
    <>
    <Alert status="warning" mb="20px" w='-webkit-fit-content' alignItems='center' mt='20px' borderRadius='10px' display={{ md: "inherit", xl: "none" }} mr='20px' ml='20px'>
      <AlertIcon />
      <AlertDescription>
      Since 30 Oct 16:00 UTC, Pedrodig has been actively monitoring objkt.com's live data and is still populating 7-day and 30-day data. Thank you for your patience.
      </AlertDescription>
    </Alert>
    
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}
      mb='20px'>
        
      <Flex px='25px' justify='space-between'  align='center' direction={{ base: "column", md: "row", xl: "row" }}>
        
      <Flex px='25px' justify='space-between' mb='20px' align='center' direction={{ base: "column", md: "column", xl: "column" }}
        >
<Text
            marginTop="1rem"
            alignSelf={{ base: "auto", md: "baseline", xl: "baseline" }}

            fontSize={{ base: "medium", md: "large", xl: "large" }}

            fontWeight="900"
          >
            Collections on Objkt with Transactions in the Last:
          </Text>
      <ButtonGroup variant="outline" spacing="6" m="10px">  
      <Flex
          direction="row"
          width={{ base: "90vw", md: "45vw", xl: "45vw" }}
          sx={{
            base: { width: "-webkit-fill-available" },
            md: { width: "auto" },
            xl: { width: "auto" },
          }}
          justifyContent={{ base: "space-evenly", md: "space-evenly", xl: "start" }}
        >
     
     {/* <Button 
          onClick={() => handleRangeChange('all')}
          style={range === 'all' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          m={{ base: "unset", md: "unset", xl: "10px" }}
        >
          All
        </Button> */}
        <Button 
          onClick={() => handleRangeChange('30d')}
          style={range === '30d' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          disabled={true}
          m={{ base: "unset", md: "unset", xl: "10px" }}
        >
          30 Days
        </Button>
        <Button 
          onClick={() => handleRangeChange('7d')}
          style={range === '7d' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          // disabled={true}
          m={{ base: "unset", md: "unset", xl: "10px" }}
        >
          7 Days
        </Button>
        <Button 
          onClick={() => handleRangeChange('24h')}
          style={range === '24h' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          m={{ base: "unset", md: "unset", xl: "10px" }}  
        >
          24 Hours
        </Button>
      
      </Flex>
      </ButtonGroup>
      </Flex>
      <Alert status="warning" mb="20px" w='-webkit-fit-content' alignItems='center' mt='20px' borderRadius='10px' display={{ sm: "none",md: "none", xl: "inherit" }}>
      <AlertIcon />
      <AlertDescription>
      Since 30 Oct 16:00 UTC, Pedrodig has been actively monitoring objkt.com's live data and is still populating 7-day and 30-day data. Thank you for your patience.
      </AlertDescription>
    </Alert>
      {/* <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Arts Table
        </Text> */}
        

        <Flex direction="row" m="20px" >
        <Checkbox
    isChecked={oneOfOneEdition}
    onChange={(e) => {
        setOneOfOneEdition(e.target.checked);
        handleURLParameters();
    }}
    mr='20px'
>
    1/1 Edition
</Checkbox>
<Checkbox
    isChecked={secondaryCount}
    onChange={(e) => {
        setSecondaryCount(e.target.checked);
        handleURLParameters();
    }}
>
    Has Secondary
</Checkbox>
      </Flex>



      </Flex>
      </Card>
      <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", "2xl": "hidden" }}
        css={ScrollbarCSS}
      >
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
      <Thead>
  {headerGroups.map((headerGroup, index) => (
    <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
      {headerGroup.headers.map((column, index) => (
        <Th
          style={{
            cursor: column.id === "artName" || column.id === "artist" ? 'default' : 'pointer'
          }}
          key={index}
          pe='10px'
          borderColor={borderColor}
          onClick={() => {
            if (column.id !== "artName" && column.id !== "artist") {
                if (sortedColumn === column.id) {
                    handleSort(column.id, sortDirection === 'asc' ? 'desc' : 'asc');
                } else {
                    handleSort(column.id, 'desc');
                }
            }
        }}
        
        >
          <Flex
            justify='space-between'
            align='center'
            fontSize={{ sm: "10px", lg: "12px" }}
            color='gray.400'
          >
            {column.render("Header")}
            <span>
              {(column.id !== "artName" && column.id !== "artist" && column.id === sortedColumn)
                ? (sortDirection === 'desc' ? ' 🔽' : ' 🔼')
                : ''
              }
            </span>
          </Flex>
        </Th>
      ))}
    </Tr>
  ))}
</Thead>

        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  if (cell.column.id === "artImage" || cell.column.id === "artistImage") {
                    return null; // do not render the cell if column id is "artImage" or "artistImage"
                  }

                  let data = "";
                  if (cell.column.id === "artName") {
                    data = (
                      <Flex align="center" onClick={() => history.push(`/collection/${row.original.contractAddress}`)} style={{cursor: 'pointer'}}>
           
                        <Avatar
              src={ipfsToHttp(row.original.artImage)}
              w='35px'
              h='35px'
              mr='10px'
            />
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {truncate(cell.value, 30)}
                        </Text>
                      </Flex>
                    );
                  }
                   else if (cell.column.id === "artist") {
                    data = (
                      <Flex align="center" onClick={() => history.push(`/artist/${row.original.artistWallet}`)} style={{cursor: 'pointer'}}>
                        <Avatar
              src={ipfsToHttp(row.original.artistImage.logo)}
              w='35px'
              h='35px'
              mr='10px'
            />
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  }
                   else if (cell.column.id === "lastDeal") {
                    data = (
                      <Flex align="center">
                        
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value === 0 ? "-" : calculateRelativeTime(cell.value)}
                        </Text>
                      </Flex>
                    );
                  } else {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex align="center" flexDirection='row' justifyContent='center'>
      <Button
        onClick={handlePreviousPage}
        disabled={currentPage === 1} 
        m='10px'
      >
        Back
      </Button>
      <Input
        value={currentPage}
        onChange={handlePageChange}
        type="number"
        min={1}
        style={{ width: '50px', textAlign: 'center' }}
        m='10px'
      />
      <Button
        onClick={handleNextPage}
        disabled={tableData.length < itemsPerPage}
        m='10px'
      >
        Next
      </Button>
      </Flex>
    </Card>
    </>
  );
}