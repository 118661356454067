// Chakra imports
import {
  Box,
  Flex,
  Text,
  Link,
  useColorModeValue,
  Button,
  ButtonGroup,
  Spinner,
  AlertDescription,
  AlertIcon,
  Alert,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaDiscord } from "react-icons/fa";
// Custom components
import General from "views/admin/artist/components/General";
import Collects from "views/admin/artist/components/Collects";
import Storage from "views/admin/artist/components/Storage";
import SellStats from "views/admin/artist/components/SellStats";
import ListToSell from "views/admin/artist/components/ListToSell";
import Collectors from "views/admin/artist/components/Collectors";
import Chart from "views/admin/artist/components/Chart";
import Auction from "views/admin/artist/components/Auction";
import SellWays from "views/admin/artist/components/SellWays";
import NFT from "components/card/Artist";
import ReactGA from 'react-ga';
import { useHistory } from "react-router-dom";

// Assets
import React from "react";

const calcPrice = (value, defaultReturn = "-") => {
  if (!value) {
    return defaultReturn;
  }
  const deal = parseInt(value) / 1000000;
  if (deal < 1) {
    return Number(deal.toFixed(2)).toLocaleString() + " Xtz";
  } else if (deal >= 1 && deal < 10) {
    return Number(deal.toFixed(1)).toLocaleString() + " Xtz";
  } else {
    return Number(deal.toFixed(0)).toLocaleString() + " Xtz";
  }
};

export default function Overview() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { walletAddress } = useParams(); // Get contractAddress and tokenId from the URL
  const [range, setRange] = useState("all");
  const [data, setData] = useState();
  const [collectorData, setCollectorData] = useState();
  const [collections, setCollections] = useState([]);
  const [selectedSort, setSelectedSort] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const MemoizedStorage = React.memo(Storage);
  const MemoizedGeneral = React.memo(General);

  const history = useHistory();
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  
    // Listen for changes
    return history.listen(location => {
      ReactGA.pageview(location.pathname + location.search);
    });
  }, [history]);
  
  const handleRangeChange = async (newRange) => {
    setLoading2(true);
    setLoading(true);
    setRange(newRange);
    try {
      let fetchURL = `https://api.pedrodig.xyz/artist/${newRange}/${walletAddress}`;
      const response = await fetch(fetchURL, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const mid = await response.json();
      console.log(range, mid);
      if (mid.error === "No artist found with this address") {
        setIsErrorDialogOpen(true);
        setData(mid);
        console.log(range, isErrorDialogOpen);
        setLoading2(false);
        return;
      }
      setData(mid);
      setLoading2(false);
      fetchCollections(mid.collectionsAddreses, range)
        .then((data) => setCollections(data))
        .catch((err) => console.error(err));
      setLoading(false);
      setIsErrorDialogOpen(false);
    } catch (error) {
      console.error("Error fetching art data:", error);
    }
  };

  const handleCollectorDataFetch = async (range) => {
    try {
      let fetchURL = `https://api.pedrodig.xyz/collector/${range}/${walletAddress}`;
      const response = await fetch(fetchURL, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const collectorData = await response.json();

      setCollectorData(collectorData);
    } catch (error) {
      console.error("Error fetching collector data:", error);
    }
  };

  const fetchCollections = async (contractAddresses = [], range) => {
    const response = await fetch(
      `https://api.pedrodig.xyz/collections/${range}/batch`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({ contractAddresses }),
      }
    );
    const data = await response.json();
    console.log("fetchCollections", data);
    return data;
  };

  const bgColorPrimary = useColorModeValue("#7551ff", "#7551ff");
  const selectedStyle = { backgroundColor: bgColorPrimary };

  useEffect(() => {
    handleRangeChange(range);
    handleCollectorDataFetch(range);
  }, [walletAddress]);

  const sortByProfit = () => {
    const sortedCollections = [...collections].sort(
      (a, b) => b.avgProfitPercentage - a.avgProfitPercentage
    );
    setCollections(sortedCollections);
    setSelectedSort("profit");
  };

  const sortByCollectors = () => {
    const sortedCollections = [...collections].sort(
      (a, b) => b.totalCollectors - a.totalCollectors
    );
    setCollections(sortedCollections);
    setSelectedSort("collectors");
  };

  const initialSize = window.innerWidth <= 768 ? '36px' : '24px';

  // Create a state for the icon size
  const [iconSize, setIconSize] = useState(initialSize);

  // Update icon size when window is resized
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIconSize('36px');
      } else {
        setIconSize('24px');
      }
    };

    window.addEventListener('resize', handleResize);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <Link
        href="https://discord.gg/dgaSZr5qdw"
        isExternal
        _hover={{ textDecoration: "none", color: "white" }}
      >
        <Box
          top="0"
          left="0"
          right="0"
          width="100%"
          bg={useColorModeValue("purple.600", "purple.700")}
          color="white"
          p={2}
          textAlign="center"
          zIndex="1000"
          mb="10px"
          borderRadius="5px"
        >
          <Flex alignItems="center" justifyContent="center">
          <FaDiscord style={{ margin: "8px", fontSize: iconSize }} />
          Experimental tool, but with your help, it can be useful! Join our Discord for feedback & bug reports.
          </Flex>
        </Box>
      </Link>
      {loading2 && (
        <Flex align="center" justify="center" minHeight="10vh">
          <Spinner size="xl" />
        </Flex>
      )}
      {isErrorDialogOpen && (
        <Alert
          status="warning"
          mb="20px"
          w="-webkit-fit-content"
          alignItems="center"
          mt="20px"
          borderRadius="10px"
          mr="20px"
          ml="20px"
        >
          <AlertIcon />
          <AlertDescription>
            This artist is not currently listed in the database.
          </AlertDescription>
        </Alert>
      )}
      <Flex direction={{ base: "column", md: "column", xl: "row" }}
      alignItems={{ base: 'center', md: 'center', xl: 'stretch' }}>
        <Flex
          direction="column"
          m="20px"
          width={{ base: "90vw", md: "90vw", xl: "25vw" }}
        >
          <Text
            marginLeft="2rem"
            marginBottom="1rem"
            fontSize="x-large"
            fontWeight="900"
          >
            Artist Details
          </Text>
          <ButtonGroup variant="outline" spacing="6" m="10px">  
      <Flex
          direction="row"
          width={{ base: "90vw", md: "45vw", xl: "45vw" }}
          sx={{
            base: { width: "-webkit-fill-available" },
            md: { width: "auto" },
            xl: { width: "auto" },
          }}
          justifyContent={{ base: "space-evenly", md: "space-evenly", xl: "start" }}
        >
     
     <Button 
          onClick={() => handleRangeChange('all')}
          style={range === 'all' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          m={{ base: "unset", md: "unset", xl: "10px" }}
        >
          All
        </Button>
        <Button 
          onClick={() => handleRangeChange('30d')}
          style={range === '30d' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          // disabled={true}
          m={{ base: "unset", md: "unset", xl: "10px" }}
        >
          30 Days
        </Button>
        <Button 
          onClick={() => handleRangeChange('7d')}
          style={range === '7d' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          // disabled={true}
          m={{ base: "unset", md: "unset", xl: "10px" }}
        >
          7 Days
        </Button>
        <Button 
          onClick={() => handleRangeChange('24h')}
          style={range === '24h' ? selectedStyle : {}}  // Apply selected style if this is the selected range
          m={{ base: "unset", md: "unset", xl: "10px" }}  
        >
          24 Hours
        </Button>
      
      </Flex>
      </ButtonGroup>
          <MemoizedStorage
            imageUrl={data?.imageUrl}
            name={data?.name}
            description={data?.description}
            twitter={data?.twitter}
            website={data?.website}
            walletAddreess={data?.artistWallet}
          />
          {collectorData &&
            collectorData.error !== "No collector found with this address" && (
              <Collects data={collectorData} />
            )}
          <MemoizedGeneral
            totalArts={data?.totalArts}
            totalEditions={data?.totalEditions}
            totalBurned={data?.totalBurned}
            firstMintTime={data?.firstMintTime}
            lastUpdate={data?.lastUpdated}
          />

          <Auction
            successAuctions={data?.successAuctions}
            failedAuctions={data?.failedAuctions}
            avgReserveAuction={data?.avgReserveAuction}
            avgSettleAuction={data?.avgSettleAuction}
          />
        </Flex>
        <Flex
          direction="column"
          m="20px"
          width={{ base: "90vw", md: "90vw", xl: "40vw" }}
        >
          <SellStats
            totalVol={
              data ? data.totalPrimarySell + data.totalSecondarySell : 0
            }
            totalPrimarySell={data ? data.totalPrimarySell : 0}
            totalSecondarySell={data ? data.totalSecondarySell : 0}
            totalProfitAmount={data ? data.totalProfitAmount : 0}
            avgProfitAmount={data ? data.avgProfitAmount : 0}
            avgProfitPercentage={data ? data.avgProfitPercentage : 0}
            secondaryCount={data ? data.totalSecondaryCount : 0}
            primaryCount={data ? data.totalPrimaryCount : 0}
            primaryAvgDeal={data ? data.primaryAvgDeal : 0}
            secondaryAvgDeal={data ? data.secondaryAvgDeal : 0}
            totalArts={data ? data.totalArts : 0}
            firstMintTime={data ? data.firstMintTime : 0}
            range={range}
            avgDeal={data ? data.avgDeal : 0}
          />
          <Chart
            lastDeal={data?.lastDeal}
            saleHistory={data?.saleHistory}
            offerHistory={data?.offerHistory}
            auctionHistory={data?.auctionHistory}
            range={range}
          />
          <Collectors
            collectorsList={data?.collectorsList}
            totalHolders={data?.totalHolders}
            totalCollectors={data?.totalCollectors}
          />
        </Flex>
        <Flex
          direction="column"
          m="20px"
          width={{ base: "90vw", md: "90vw", xl: "25vw" }}
        >
          <ListToSell
            primaryListToSellTime={data?.primaryListToSellTime}
            secondaryListToSellTime={data?.secondaryListToSellTime}
          />
          <SellWays
            totalListSold={data?.totalListSold}
            totalOESold={data?.totalOESold}
            totalOfferSold={data?.totalOfferSold}
            successAuctions={data?.successAuctions}
          />
        </Flex>
      </Flex>
      <Flex direction="column">
        <Flex
          mt="45px"
          mb="20px"
          justifyContent="start"
          direction={{ base: "column", md: "row" }}
          align={{ base: "start", md: "center" }}
        >
          <Text color={textColor} fontSize="2xl" ms="24px" fontWeight="700">
            Collections
          </Text>

        </Flex>
        {loading ? (
          <Box m="20px" >
            <Spinner size="xl" />
          </Box>
        ) : (
          <Flex wrap="wrap" justify="start" mb={{ base: "20px", xl: "0px" }}>
    {collections &&
        collections.filter(collection => collection.totalArts > 0).map((collection) => (
            <Box key={collection.contractAddress} mr="20px" mb="20px" onClick={() => history.push(`/collection/${collection.contractAddress}`)} style={{cursor: 'pointer'}}>
                <NFT
                    name={collection.name}
                    image={collection.imageUrl}
                    avgProfit={`${collection.avgProfitPercentage} Xtz`}
                    avgList={collection.secondaryListToSellTime.Avg} // you may need to format this data to match your desired output
                    totalPrimarySell={calcPrice(collection.totalPrimarySell)}
                    totalSecondarySell={calcPrice(collection.totalSecondarySell)}
                    collectors={collection.totalCollectors.toString()}
                />
            </Box>
        ))}
</Flex>

        )}
      </Flex>
    </Box>
  );
}

// <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
//       {/* Main Fields */}
//       <Grid
//         templateColumns={{
//           base: "1fr",
//           lg: "1.34fr 1fr 1.62fr",
//         }}
//         templateRows={{
//           base: "repeat(3, 1fr)",
//           lg: "1fr",
//         }}
//         gap={{ base: "20px", xl: "20px" }}>
//         <Banner
//           gridArea='1 / 1 / 2 / 2'
//           banner={banner}
//           avatar={avatar}
//           name='Adela Parkson'
//           job='Product Designer'
//           posts='17'
//           followers='9.7k'
//           following='274'
//         />
//         <Storage
//           gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
//           used={25.6}
//           total={50}
//         />
//         <Upload
//           gridArea={{
//             base: "3 / 1 / 4 / 2",
//             lg: "1 / 3 / 2 / 4",
//           }}
//           minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
//           pe='20px'
//           pb={{ base: "100px", lg: "20px" }}
//         />
//       </Grid>
//       <Grid
//         mb='20px'
//         templateColumns={{
//           base: "1fr",
//           lg: "repeat(2, 1fr)",
//           "2xl": "1.34fr 1.62fr 1fr",
//         }}
//         templateRows={{
//           base: "1fr",
//           lg: "repeat(2, 1fr)",
//           "2xl": "1fr",
//         }}
//         gap={{ base: "20px", xl: "20px" }}>
//         <Projects
//           gridArea='1 / 2 / 2 / 2'
//           banner={banner}
//           avatar={avatar}
//           name='Adela Parkson'
//           job='Product Designer'
//           posts='17'
//           followers='9.7k'
//           following='274'
//         />
//         <General
//           gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
//           minH='365px'
//           pe='20px'
//         />
//         <Notifications
//           used={25.6}
//           total={50}
//           gridArea={{
//             base: "3 / 1 / 4 / 2",
//             lg: "2 / 1 / 3 / 3",
//             "2xl": "1 / 3 / 2 / 4",
//           }}
//         />
//       </Grid>
//     </Box>
