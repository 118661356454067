// Chakra imports
import { Icon, SimpleGrid, Text, useColorModeValue, Flex, Box, Center } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Information from "views/admin/artist/components/Information";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";

const calcPrice = (value, defaultReturn = "-") => {
  if (!value) {
    return defaultReturn;
  }
  const deal = parseInt(value) / 1000000;
  if (deal < 1) {
    return Number(deal.toFixed(2)).toLocaleString() + " Xtz";
  } else if (deal >= 1 && deal < 10) {
    return Number(deal.toFixed(1)).toLocaleString() + " Xtz";
  } else {
    return Number(deal.toFixed(0)).toLocaleString() + " Xtz";
  }
};

function calculateAverageTime(firstTime, arts) {
  // Parse the firstTime into a JavaScript Date object
  const firstDate = new Date(firstTime);
  // Get the current date
  const now = new Date();
  
  // Calculate the difference in milliseconds
  const diff = now - firstDate;
  
  // Convert the difference to seconds
  const diffInSeconds = diff / 1000;
  
  // Calculate the average time per art in seconds
  const avgTimePerArt = diffInSeconds / arts;

  return avgTimePerArt;
}

const calculateRelativeTime = (mintTime) => {
  const diffSeconds = Math.abs(mintTime);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffSeconds / (60 * 60));
  const diffDays = Math.floor(diffSeconds / (60 * 60 * 24));
  const diffWeeks = Math.floor(diffSeconds / (60 * 60 * 24 * 7));
  const diffMonths = Math.floor(diffSeconds / (60 * 60 * 24 * 30)); // Approximation

  if (diffSeconds === 0) {
    return `-`;
  } else if (diffSeconds < 60) {
    return `${diffSeconds}s`;
  } else if (diffMinutes < 60) {
      return `${diffMinutes}m`;
  } else if (diffHours < 24) {
      const remainingMinutes = diffMinutes % 60;
      return `${diffHours}h and ${remainingMinutes}m`;
  } else if (diffDays < 7) {
      const remainingHours = diffHours % 24;
      return `${diffDays}d and ${remainingHours}h`;
  } else if (diffWeeks < 4) {  // If less than 4 weeks, we still consider it as weeks
      const remainingDays = diffDays % 7;
      return `${diffWeeks}w and ${remainingDays}d`;
  } else {
      const remainingWeeks = diffWeeks % 4;
      return `${diffMonths}M and ${remainingWeeks}w`;
  }
}


// Assets
export default function SellStats(props) {
  const { range, totalArts, firstMintTime,totalVol, totalPrimarySell, totalSecondarySell, totalProfitAmount, avgProfitAmount, avgProfitPercentage, secondaryCount, primaryCount,primaryAvgDeal, secondaryAvgDeal,avgDeal, ...rest } = props;

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card mb={{ base: "20px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='xl'
        mt='10px'
        mb='8px'
        ml= '10px'
        align= 'left'>
        Sell Stats
      </Text>


      <SimpleGrid columns={{ base: '1', md: '2', xl: '2' }} gap='20px'>
      <MiniStatistics
      startContent={
        <IconBox
          w='56px'
          h='56px'
          bg={boxBg}
          icon={
            <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
          }
        />
      }
      name='Total Volume'
      value={calcPrice(totalVol)}
    />
      <MiniStatistics
      startContent={
        <IconBox
          w='56px'
          h='56px'
          bg={boxBg}
          icon={
            <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
          }
        />
      }
      name='Avg. Price'
      value={calcPrice(avgDeal)}
    />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Primary Sell'
          value={calcPrice(totalPrimarySell)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Secondary Sell'
          value={calcPrice(totalSecondarySell)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Primary Deals'
          value={primaryCount ? primaryCount : "-"}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Secondary Deals'
          value={secondaryCount ? secondaryCount : "-"}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Avg. Primary Price'
          value={calcPrice(primaryAvgDeal)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Avg. Secondary Price'
          value={calcPrice(secondaryAvgDeal)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Secondary Profit'
          value={calcPrice(totalProfitAmount)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Avg. Secondary Profit'
          value={calcPrice(avgProfitAmount)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Avg. Profit Percentage'
          value={(avgProfitPercentage && avgProfitPercentage > 0.1) ? avgProfitPercentage.toFixed(0) + "%" : "-"}
        />
        
        
        {range === 'all' && <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Mint Speed'
          value={firstMintTime ? calculateRelativeTime(calculateAverageTime(firstMintTime, totalArts)) : "-"}
        />}
     
      </SimpleGrid>
    </Card>
  );
}
