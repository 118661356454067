// Chakra imports
import { Icon, SimpleGrid, Text, useColorModeValue, Flex, Box, Center } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Information from "views/admin/collection/components/Information";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";


const calcPrice = (value, defaultReturn = "-") => {
  if (!value) {
    return defaultReturn;
  }
  const deal = parseInt(value) / 1000000;
  if (deal < 1) {
    return Number(deal.toFixed(2)).toLocaleString() + " Xtz";
  } else if (deal >= 1 && deal < 10) {
    return Number(deal.toFixed(1)).toLocaleString() + " Xtz";
  } else {
    return Number(deal.toFixed(0)).toLocaleString() + " Xtz";
  }
};




// Assets
export default function SellStats(props) {
  const { data, ...rest } = props;
console.log("sellstatssellstatssellstatssellstats", data)
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest} >
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='xl'
        mt='10px'
        mb='8px'
        ml= '30px'
        align= 'left'>
        Stats
      </Text>



      
      <SimpleGrid columns={{ base: '1', md: '2', xl: '2' }} gap='20px'>
      <MiniStatistics
      startContent={
        <IconBox
          w='56px'
          h='56px'
          bg={boxBg}
          icon={
            <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
          }
        />
      }
      name='Total Volume'
      value={calcPrice(parseInt(data.totalPrimarySell) + parseInt(data.totalSecondarySell))}
    />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Primary Sell'
          value={calcPrice(data.totalPrimarySell)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Secondary Sell'
          value={calcPrice(data.totalSecondarySell)}
        />
        
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Primary Count'
          value={data.totalPrimaryCount ? Number(data.totalPrimaryCount.toFixed(0)).toLocaleString() : "-"}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Secondary Count'
          value={data.totalSecondaryCount ? Number(data.totalSecondaryCount.toFixed(0)).toLocaleString() : "-"}
        />

<MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Avg. Primary Price'
          value={calcPrice(data.primaryAvgDeal)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Avg. Secondary Price'
          value={calcPrice(data.secondaryAvgDeal)}
        />
        
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Buyer'
          value={data.totalBuyer ? Number(data.totalBuyer.toFixed(0)).toLocaleString() : "-"}
        />

<MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Seller'
          value={data.totalSeller ? Number(data.totalSeller.toFixed(0)).toLocaleString() : "-"}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Arts Traded'
          value={data.totalArts ? Number(data.totalArts.toFixed(0)).toLocaleString() : "-"}
        />
        
        {/* <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Editions Traded'
          value={data.totalEditions ? Number(data.totalEditions.toFixed(0)).toLocaleString() : "-"}
        /> */}
     
      </SimpleGrid>
    </Card>
  );
}
